import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";

const ClassicHeatmap = (props) => {
  let heatmapdata = [];
  let commonProps = {};

  console.log("heatmap_classic", props.analysis);

  for (let i = 0; i < props.data.names.length; i++) {
    const heatmapRow = {
      id: i + 1 + ". " + props.data.names[i].slice(0, 25),
      data: [
        { x: "River Flood", y: props.data.values[i]["River Flood"] },
        { x: "Drought", y: props.data.values[i]["Drought"] },
        { x: "Storm Surge", y: props.data.values[i]["Storm Surge"] },
        { x: "Extreme Heat", y: props.data.values[i]["Extreme Heat"] },
        { x: "Snow Fall", y: props.data.values[i]["Snow Fall"] },
        { x: "Rainfall Flood", y: props.data.values[i]["Rainfall Flood"] },
        { x: "Typhoon", y: props.data.values[i]["Typhoon"] },
        { x: "Sea Level Rise", y: props.data.values[i]["Sea Level Rise"] },
        { x: "Landslide", y: props.data.values[i]["Landslide"] },
        { x: "Wild Fire", y: props.data.values[i]["Wild Fire"] },
      ],
    };

    // Conditionally add "Extreme Cold" based on props.analysis
    if (props.analysis === "ssp") {
      heatmapRow.data.push({
        x: "Extreme Cold",
        y: props.data.values[i]["Extreme Cold"],
      });
    }

    heatmapdata.push(heatmapRow);
  }

  let n = props.data.names.length;

  commonProps = {
    height: n === 1 ? 200 : n * 120,
  };

  console.log("data_heatmap", heatmapdata);

  return (
    <ResponsiveHeatMap
      {...commonProps}
      data={heatmapdata}
      keys={props.variable}
      indexBy="id"
      hoverTarget="cell"
      margin={{ top: 80, right: 0, bottom: 80, left: 250 }}
      valueFormat=">-.2s"
      isInteractive={true}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: "",
        legendOffset: 46,
      }}
      colors={{
        type: "diverging",
        scheme: "yellow_orange_red",
        divergeAt: 0.5,
        minValue: 0,
        maxValue: 100,
      }}
      emptyColor="#555555"
      /*legends={[
        {
          anchor: "bottom",
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Value →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}*/
    />
  );
};

export default ClassicHeatmap;
