import React, { useRef, useEffect, useState } from "react";
import ReactMapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Row, Col } from "react-bootstrap";
import { Icon } from "semantic-ui-react";
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl";

const countryNames = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (North)",
  "Korea (South)",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  // This list should be exhaustive for all recognized countries
];

mapboxgl.accessToken =
  "pk.eyJ1IjoiYmFjay1vZmZpY2UtbWFuYWdlbWVudCIsImEiOiJjbGZxcmJlbjgwMWJxNDRwYjNpdXp0cGJ6In0.nfzF7xWo_S0Q90LhXBurxw";

export default function GenerateMap(props) {
  const mapContainer = useRef(null);
  const markerRef = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(114.1838);
  const [lat, setLat] = useState(22.2797);
  const [zoom, setZoom] = useState(5.5);
  const [mapData, setMapData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [locationSet, setLocationSet] = useState(false); // Track if a location has been set
  //const [distance, setDistance] = useState(5); // Default range in kilometers
  const distances = [1, 3, 5]; // Predefined distance options in kilometers
  const [distance, setDistance] = useState(distances[0]); // Default distance: 5 km
  const [isLocationSet, setIsLocationSet] = useState(false); // Initially false
  const distanceRef = useRef();

  function getBoundingBox(longitude, latitude, distanceKm) {
    const earthRadiusKm = 111; // Approximation for converting degrees to km

    const deltaLat = distanceKm / earthRadiusKm;
    const deltaLng =
      distanceKm / (earthRadiusKm * Math.cos((latitude * Math.PI) / 180));

    const southwest = [longitude - deltaLng, latitude - deltaLat];
    const northeast = [longitude + deltaLng, latitude + deltaLat];

    return [southwest, northeast];
  }

  useEffect(() => {
    distanceRef.current = distance;
  }, [distance]);

  const handleDeleteMapData = () => {
    if (markerRef.current) {
      markerRef.current.remove();
    }
    setMapData([]);
    setShowTable(false);
    setLocationSet(false); // Reset location flag
  };

  const calculateBoundingBox = (lng, lat, rangeInKm = distance) => {
    const rangeInMeters = rangeInKm * 500;

    // 1 degree latitude = ~111,320 meters; use this to calculate bounding box
    const deltaLng = rangeInMeters / 111320; // longitude offset
    const deltaLat = rangeInMeters / 111320; // latitude offset

    const topLeft = [lng - deltaLng, lat + deltaLat];
    const bottomRight = [lng + deltaLng, lat - deltaLat];

    console.log("bounding box", topLeft, bottomRight);

    return [
      [topLeft[0], topLeft[1]],
      [bottomRight[0], topLeft[1]],
      [bottomRight[0], bottomRight[1]],
      [topLeft[0], bottomRight[1]],
      [topLeft[0], topLeft[1]], // Closing the polygon
    ];
  };

  // Function to add or update bounding box
  const addOrUpdateBoundingBox = (lng, lat, address, rangeInKm = distance) => {
    console.log("distance_update", rangeInKm);
    const boundingBoxCoords = calculateBoundingBox(lng, lat, rangeInKm);

    // If the source already exists, update its data
    if (map.current.getSource("boundingBox")) {
      map.current.getSource("boundingBox").setData({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [boundingBoxCoords],
        },
      });
    } else {
      // Add a new source and layer
      map.current.addSource("boundingBox", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [boundingBoxCoords],
          },
        },
      });

      map.current.addLayer({
        id: "boundingBox",
        type: "line",
        source: "boundingBox",
        layout: {},
        paint: {
          "line-color": "#002060",
          "line-width": 2,
        },
      });
    }

    // Fly to the clicked location
    map.current.flyTo({
      center: [lng, lat],
      zoom: 12,
    });

    // Update the state with new bounding box details
    setMapData([
      {
        id: 0,
        name: address,
        latitude: lat,
        longitude: lng,
        boundingBox: boundingBoxCoords,
        distance: rangeInKm,
      },
    ]);

    setLocationSet(true); // Lock location after setting
    setShowTable(true);
  };

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [lng, lat],
      zoom: zoom,
    });
  }, [lng, lat, zoom]);

  // Add geocoder control with one-time location selection restriction
  useEffect(() => {
    if (locationSet || !map.current) return; // Stop if location is already set

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      reverseGeocode: true,
      placeholder: "Search address, city, or place",
      marker: false,
      flyTo: {
        speed: 1.2,
        curve: 1,
        easing: (t) => t,
      },
      fuzzyMatch: true,
      limit: 5,
      language: navigator.language.split("-")[0],
      types:
        "country,region,postcode,district,place,locality,neighborhood,address,poi",
      minLength: 2,
      autocomplete: true,
      render: function(item) {
        let type = item.place_type[0];
        let typeIcon =
          {
            country: "🌍",
            region: "📍",
            postcode: "📮",
            district: "🏘️",
            place: "🌆",
            locality: "📌",
            neighborhood: "🏘️",
            address: "🏠",
            poi: "⭐",
          }[type] || "📍";

        return `${typeIcon} ${item.place_name}`;
      },
      filter: function(item) {
        return item.place_name && item.place_type;
      },
    });
    map.current.addControl(geocoder);

    // Listen for input in geocoder
    geocoder.on("results", (e) => {
      // Get search suggestions in lowercase
      const suggestions = e.features.map((feature) =>
        feature.place_name.toLowerCase()
      );

      // Get the input text in lowercase
      const inputText = e.config.query.toLowerCase();
      const inputCountry = inputText
        .split(",")
        .pop()
        .trim();

      const suggestionCountries = suggestions.map((s) =>
        s
          .split(",")
          .pop()
          .trim()
      );
      const countryInSuggestions = suggestionCountries.some(
        (country) => country === inputCountry
      );

      console.log(
        "Input:",
        inputText,
        "Suggestions:",
        suggestions.some((s) => s.includes(inputCountry))
      );

      // Check if the input text matches any country name in suggestions
      if (isCountryName(inputText) && !countryInSuggestions) {
        console.log("calling center");
        // No match in suggestions - add marker to the center of the input country
        fetchCountryCenter(inputText).then((center) => {
          if (center) {
            addOrUpdateBoundingBox(center.lng, center.lat, inputText, distance);
          }
        });
      } else {
        console.log("normal one");
        // If there’s a match, proceed with the usual geocoding behavior (first suggestion)
        if (e.features.length > 0) {
          const [lng, lat] = e.features[0].center;
          addOrUpdateBoundingBox(lng, lat, e.features[0].place_name, distance);
        }
      }
    });
  }, [locationSet, distance]);

  // Helper function to check if input text is likely a country name
  function isCountryName(input) {
    // Convert input to lowercase and trim spaces
    const cleanedInput = input.trim().toLowerCase();
    console.log("clean", cleanedInput);

    // Check if cleaned input matches any country name in lowercase
    return countryNames.some((country) =>
      cleanedInput.includes(country.toLowerCase())
    );
  }

  // Helper function to fetch country center coordinates
  async function fetchCountryCenter(countryName) {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      countryName
    )}.json?types=country&access_token=${mapboxgl.accessToken}`;
    const response = await fetch(url);
    const data = await response.json();

    if (data.features && data.features.length > 0) {
      return {
        lng: data.features[0].center[0],
        lat: data.features[0].center[1],
      };
    }
    console.log("country", data.features);
    return null;
  }

  // Handle map click with one-time location selection restriction
  useEffect(() => {
    if (locationSet || !map.current) return;

    const handleClick = (e) => {
      console.log("onclick distance", distanceRef.current);
      if (locationSet) return;

      const lng = e.lngLat.lng;
      const lat = e.lngLat.lat;

      const bounds = getBoundingBox(lng, lat, 15);

      // Update map to fit the bounds
      map.current.fitBounds(bounds, {
        padding: 20, // Add padding for better visibility
        duration: 1000, // Smooth transition
      });

      setIsLocationSet(true); // Disable the dropdown

      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`
      )
        .then((response) => response.json())
        .then((data) => {
          const address = data.features[0]?.place_name.split(",")[0];
          addOrUpdateBoundingBox(lng, lat, address, distanceRef.current);
        });
    };
    // map.current.off("click", handleClick);

    map.current.on("click", handleClick);

    return () => {
      if (map.current) map.current.off("click", handleClick);
    };
  }, []);

  // Function to add marker and set location details
  const addMarkerAndFlyTo = (lng, lat, address) => {
    if (markerRef.current) markerRef.current.remove(); // Remove any existing marker

    // Create new marker and add it to the map
    markerRef.current = new mapboxgl.Marker({ color: "black", draggable: true })
      .setLngLat([lng, lat])
      .addTo(map.current);

    map.current.flyTo({ center: [lng, lat], zoom: 12 });

    setMapData([
      {
        id: 0,
        marker: markerRef.current,
        color: "black",
        name: address,
        latitude: lat,
        longitude: lng,
        distance: distance,
      },
    ]);

    setLocationSet(true); // Lock location after setting
    setShowTable(true);

    // Handle marker drag to update location
    markerRef.current.on("dragend", () => {
      const lngLat = markerRef.current.getLngLat();
      fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${mapboxgl.accessToken}`
      )
        .then((response) => response.json())
        .then((data) => {
          const newAddress = data.features[0]?.place_name.split(",")[0];
          setMapData((prevMapData) =>
            prevMapData.map((item) =>
              item.id === 0
                ? {
                    ...item,
                    name: newAddress,
                    latitude: lngLat.lat,
                    longitude: lngLat.lng,
                    distance: distance,
                  }
                : item
            )
          );
        });
    });
  };

  const getRandomColor = () =>
    "#" + Math.floor(Math.random() * 16777215).toString(16);

  useEffect(() => {
    const transformedMapData = mapData.map(
      ({ name, latitude, longitude, color, distance }) => ({
        name,
        latitude,
        longitude,
        color,
        distance,
      })
    );
    props.onMapDataChange(transformedMapData);
  }, [mapData]);

  useEffect(() => {
    if (mapData.length > 0) {
      const { longitude, latitude, name } = mapData[0];
      console.log("Updating bounding box:", {
        longitude,
        latitude,
        name,
        distance,
      });

      addOrUpdateBoundingBox(longitude, latitude, name, distance);
    }
  }, [distance]);

  return (
    <Row>
      <Col md={12} sm={12} xs={12} className="mapcol" id="MapModule">
        <p className="toppara" style={{ marginBottom: "5px" }}>
          Mark locations to analyze
        </p>
        <div>
          <label htmlFor="distance-dropdown" style={{ marginRight: "10px" }}>
            Select Bounding Box Distance (km):
          </label>
          <select
            id="distance-dropdown"
            value={distance}
            onChange={(e) => setDistance(Number(e.target.value))} // Update distance on change
            style={{ padding: "5px 10px", fontSize: "14px" }}
          >
            {distances.map((d) => (
              <option key={d} value={d}>
                {d} km
              </option>
            ))}
          </select>
        </div>

        {/* Table Section - Conditionally render based on showTable state */}
        {showTable && (
          <div className="table-container">
            <div className="table">
              <div className="table__body">
                <div
                  className="table__row table__heading"
                  style={{ width: "100%" }}
                >
                  <div className="table__cell">Address</div>
                  <div className="table__cell">Latitude</div>
                  <div className="table__cell">Longitude</div>
                  <div className="table__cell">Delete</div>
                </div>
                {mapData.map((item) => (
                  <div className="table__row" key={item.id}>
                    <div className="table__cell" style={{ color: "black" }}>
                      <Icon
                        name="map marker alternate"
                        style={{ color: item.color }}
                      />
                      {item.name}
                    </div>
                    <div className="table__cell" style={{ color: "black" }}>
                      {item.latitude.toFixed(5)}
                    </div>
                    <div className="table__cell" style={{ color: "black" }}>
                      {item.longitude.toFixed(5)}
                    </div>
                    <div className="table__cell">
                      <Icon
                        name="trash alternate"
                        onClick={handleDeleteMapData}
                        style={{ cursor: "pointer", color: "black" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Map Section */}
        <div ref={mapContainer} className="location-map-container" />
      </Col>
    </Row>
  );
}
