import React, { Component } from "react";

import {
  Header,
  Icon,
  Menu,
  Label,
  Button,
  Grid,
  Image,
  Form,
  Input,
  Modal,
  Popup,
  Dropdown,
} from "semantic-ui-react";
import "./location.css";
import { loadModules } from "esri-loader";
import { CSVReader } from "react-papaparse";
import logo from "../assets/logo.png";
import home from "../assets/home.png";
import add from "../assets/images/add.png";
import sample from "../assets/image.png";
import ll1 from "../assets/logo_land.png";
import search from "../assets/search.png";
import { Accordion, Card } from "react-bootstrap";

import upload from "../assets/upload.png";
import hsi from "../assets/hsi.png";
import sp from "../assets/sp.png";
import dax from "../assets/dax.png";
import dawjones from "../assets/dowjones.png";
import nasdaq from "../assets/nasdaq.png";
import euro from "../assets/euro.png";
import nifty from "../assets/nifty.png";
import nikkie from "../assets/nikkei.png";

import toastr from "toastr";

import { connect } from "react-redux";
import { locus, auth } from "../actions";
import { Checkbox, Typography, Radio, Select, Spin } from "antd";

import { company } from "../actions";
import Suggestion from "./suggestion";
import PortfolioSpinner from "./loader";
import GenerateMap from "./generate_map";
import "toastr/build/toastr.min.css";
import { CSVLink } from "react-csv";
import { changeTitle } from "../redux/actions";
import { Redirect } from "react-router-dom";
import { Tabs, Tab, Row, Col, Breadcrumb, Container } from "react-bootstrap";
import video from "../assets/video.mp4";
import video1 from "../assets/video.gif";

import TransitionTable from "./transitiontable";

import { Table } from "antd";

let mapcards = [];

const scenarioOptions = ["0.0", "2.6", "4.5", "8.5"];
const periodOptions = ["100", "250"];
const yearOptions = ["2020", "2030", "2050"];

const othersOptions = [
  { key: "Flood", value: "River Flood", text: "Flood" },
  { key: "Rainfall", value: "Rainfall", text: "Rainfall" },

  { key: "Drought Index", value: "Drought Index", text: "Drought Index" },
  //{ key: "Landslide", value: "Landslide", text: "Landslide" },
  { key: "Storm Surge", value: "Storm Surge", text: "Storm Surge" },

  { key: "Extreme heat", value: "Extreme heat", text: "Extreme heat" },
  { key: "Typhoon", value: "Typhoon", text: "Typhoon" },
];

class CreateMap extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }

  state = {
    upload: "",
    index: "",
    file: false,
    locations: [],
    query: "",
    availableDatasets: {},
    selectedVariable: "",

    farmVariables: [],
    energyVariables: [],
    option: "",
    variables: [],
    buildingVariables: [],
    confirmationOpen: false, // state to control confirmation modal

    overwrite: "false",
    company: [],
    portfolio: "",
    selectedOption: null,
    newlocations: [],
    modalOpen: false,
    videoOpen: false,
    loading: false,
    long: 34.0,
    lat: 39.0,
    zoom: 1,
    continent: "Continents",
    selectedTags: [],
    allTags: [],
    buildingselectedTags: [],
    allbuildingTags: [],
    optional: "",
    infoOpen: false,
    buildinginfoOpen: false,
    restrictOpen: false,
    address: "",
    companyloading: false,
    search_loading: false,
    buildingmodalOpen: false,
    search_type: "",
    index: "HSI",
    modalIndicesOpen: false,
    portfolio_value: "",
    portfolio_name: "",
    modalCompanyOpen: false,
    modalFileOpen: false,
    csv: "",
    selectedScenarios: [],
    selectedYears: [],
    distance: "1",
    return_period: "",
    selections: [],
    selectDisabled: false,
  };

  handleSelectChange = (value) => {
    this.setState({
      selections: value,
      // Only reset distance if all selections are cleared
      distance: value.length === 0 ? null : this.state.distance,
    });
  };

  handleVariableChange = (value) => {
    this.setState({
      selectedVariable: value,
      selections: [], // Clear selections when changing variable
      distance: null, // Clear distance when changing variable
    });
  };

  handleDistanceChange = (value) => {
    this.setState({ distance: value });
    localStorage.setItem("distance", value);
  };
  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  handleIndex = (e) => {
    console.log("checked", e.target.id);

    if (this.state.index == e.target.id) {
      this.setState({ index: "" });
    } else {
      this.setState({ index: e.target.id }, () =>
        this.props.getIndices(this.state.index)
      );
    }
  };
  handleFileChange = (e) => {
    console.log("csv", e.target.files[0]);
    this.setState({ csv: e.target.files[0] });
  };
  handleDeleteMarker = (id) => {
    // Your delete marker logic in the parent component
    console.log(`Deleting marker with ID: ${id}`);
  };
  handleChange = (e, { value }) => {
    this.setState({ selectedOption: value }, () =>
      console.log("selectedoption", this.state.selectedOption)
    );
  };
  onSubmit = (e) => {
    console.log("onSubmit triggered");
    if (e) e.preventDefault();

    // let defaultvar = ["Rainfall Flood"];
    //let variables = defaultvar.concat(this.state.variables);

    let formdata = new FormData();

    formdata.append("name", this.state.portfolio);
    formdata.append("address", this.state.locations[0].name);
    formdata.append("latitude", this.state.locations[0].latitude);
    formdata.append("longitude", this.state.locations[0].longitude);
    formdata.append(
      "portfolio_variables",
      JSON.stringify([this.state.selectedVariable])
    );
    //formdata.append("scenarios", JSON.stringify(this.state.selectedScenarios));
    //formdata.append("years", JSON.stringify(this.state.selectedYears));
    formdata.append("distance", this.state.locations[0].distance);
    formdata.append("duration_data", JSON.stringify(this.state.selections));

    this.setState({ loading: true }, () => {
      this.props.createMap(formdata);
    });
  };

  handleOpenConfirmation = () => {
    this.setState({ confirmationOpen: true });
  };

  handleCloseConfirmation = () => {
    this.setState({ confirmationOpen: false });
  };

  handleConfirm = () => {
    this.onSubmit();

    this.setState({ confirmationOpen: false });
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () =>
    this.setState({
      modalOpen: false,
      selectedVariable: "",
      distance: null,
      selections: "",
      portfolio: "",
    });

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataset !== this.props.dataset) {
      console.log("Dataset changed:", prevProps.dataset, this.props.dataset);
      this.setState({ availableDatasets: this.props.dataset }, () =>
        console.log("available datasets", this.state.availableDatasets)
      );
    }
    if (
      prevState.selectedVariable !== this.state.selectedVariable ||
      prevState.availableDatasets !== this.state.availableDatasets
    ) {
      const { availableDatasets } = this.state;
      if (
        availableDatasets.rainfall_flood !== "NOT_APPLICABLE" &&
        availableDatasets.rainfall_flood !== "NOT_AVAILABLE" &&
        availableDatasets.rainfall_flood?.length !== 0 &&
        (availableDatasets.storm_surge === "NOT_APPLICABLE" ||
          availableDatasets.storm_surge?.length === 0)
      ) {
        // Set default to "Rainfall Flood" if "Storm Surge" is unavailable
        this.handleVariableChange("Rainfall Flood");
        this.setState({ selectDisabled: true });
      } else if (
        availableDatasets.storm_surge !== "NOT_APPLICABLE" &&
        availableDatasets.storm_surge?.length !== 0 &&
        (availableDatasets.rainfall_flood === "NOT_APPLICABLE" ||
          availableDatasets.rainfall_flood === "NOT_AVAILABLE" ||
          availableDatasets.rainfall_flood?.length === 0)
      ) {
        // Set default to "Storm Surge" if "Rainfall Flood" is unavailable
        this.handleVariableChange("Storm Surge");
        this.setState({ selectDisabled: true });
      }
      console.log("Selected Variable:", this.state.selectedVariable);
      console.log("Available Datasets:", this.state.availableDatasets);
      console.log("Portfolio Name:", this.state.portfolio);
    }
  }
  isSubmitDisabled = () => {
    const {
      portfolio,
      selectedVariable,
      availableDatasets,
      selections,
      distance,
      locations,
    } = this.state;

    // Check if there are any locations selected on the map
    if (!locations || locations.length === 0) {
      return true;
    }

    // Check for valid datasets based on selected variable
    const hasValidDatasets =
      selectedVariable === "Rainfall Flood"
        ? availableDatasets.rainfall_flood &&
          Array.isArray(availableDatasets.rainfall_flood) &&
          availableDatasets.rainfall_flood.length > 0
        : selectedVariable === "Storm Surge"
        ? availableDatasets.storm_surge &&
          Array.isArray(availableDatasets.storm_surge) &&
          availableDatasets.storm_surge.length > 0
        : false;

    // All conditions must be met for the button to be enabled
    const isValid =
      locations.length > 0 && // Has locations
      selectedVariable && // Has selected variable
      hasValidDatasets && // Has valid datasets available
      selections.length > 0 && // Has selected datasets
      // distance && // Has selected distance
      portfolio &&
      portfolio.trim().length >= 3; // Has valid portfolio name

    return !isValid;
  };

  componentWillUnmount() {
    if (this.view) {
      this.view.destroy();
    }
    mapcards = [];
  }

  handleLogout = () => {
    this.props.logout();
  };
  onChange = (checkedValues) => {
    console.log("checked variables", checkedValues);
    this.setState({ variables: checkedValues });
  };
  handleScenarioChange = (checkedValues) => {
    console.log("checked scenarios", checkedValues);

    this.setState({ selectedScenarios: checkedValues });
  };
  handleYearChange = (checkedValues) => {
    console.log("checked year", checkedValues);

    this.setState({ selectedYears: checkedValues });
  };
  handlePeriodChange = (checkedValues) => {
    this.setState({ return_period: checkedValues });
  };

  handleMapDataChange = (newMapData) => {
    this.setState({ locations: newMapData }, () => {
      console.log("location", this.state.locations);
      this.fetchAvailableDatasets();
    });
  };

  fetchAvailableDatasets = () => {
    const { locations } = this.state;
    if (locations.length > 0) {
      let formdata = new FormData();
      formdata.append("latitude", locations[0].latitude);
      formdata.append("longitude", locations[0].longitude);

      this.props.fetchdataset(formdata);
    }
  };

  handleAsset = (address, latitude, longitude) => {
    mapcards.push({
      name: address,
      latitude: latitude,
      longitude: longitude,
    });
    this.setState({ locations: mapcards }, () =>
      console.log("location map", this.state.locations, mapcards.length)
    );
  };

  render() {
    const { value, continent } = this.state;
    const distanceOptions = ["1", "3", "5", "50"];
    const {
      selectedVariable,
      availableDatasets,
      selections,
      distance,
      portfolio,
    } = this.state;

    return (
      <div>
        <Container fluid style={{ backgroundColor: "#18202F" }}>
          <Row>
            <br />
            <Breadcrumb style={{ paddingLeft: "0.5%" }}>
              <Breadcrumb.Item>Map Module</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <br />
        <Container fluid style={{ marginTop: "0%" }}>
          <Row className="Location_Content">
            <Col>
              <GenerateMap
                lat={this.state.lat}
                long={this.state.long}
                zoom={this.state.zoom}
                onMapDataChange={this.handleMapDataChange}
              />
              <br />
              <Button
                primary
                onClick={this.handleOpen}
                style={{
                  fontFamily: "montserrat",
                  fontSize: "15px",
                  borderRadius: 0,
                  marginLeft: "auto",
                  float: "right",
                  backgroundColor: "#355ed814",
                  color: "#18202F",
                }}
              >
                PROCEED
              </Button>
            </Col>
          </Row>
        </Container>
        <Modal
          open={this.state.confirmationOpen}
          onClose={this.handleCloseConfirmation}
          closeIcon
        >
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <p>
              Do you want to proceed further? The map will be charged
              separately.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              className="cancel-btn"
              onClick={this.handleCloseConfirmation}
              style={{ paddingBottom: "0.55em", marginBottom: "0.5em" }}
            >
              No
            </Button>
            <Button
              className="download-map-btn"
              onClick={this.handleConfirm}
              primary
            >
              Yes
            </Button>
            <br />
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.restrictOpen}
          onClose={this.handleClose}
          closeIcon
        >
          <Modal.Header style={{ backgroundColor: "white" }}>
            Error
          </Modal.Header>
          <Modal.Content>
            {/*<video autoPlay loop muted id="video">
                <source src={video} type="video/mp4"/>
              </video>*/}
            <p>
              You are only allowed to add{" "}
              {this.props.permissions.assets_allowed} assets for Analysis
            </p>
          </Modal.Content>
        </Modal>

        <Modal
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeIcon
          className="group-wrapper"
        >
          <Modal.Header className="portfolio-header">
            Create Portfolio
          </Modal.Header>
          <Modal.Content
            style={{
              paddingLeft: "0.5rem",
              paddingTop: "0rem",
              paddingBottom: "0rem",
            }}
          >
            <Form style={{ marginTop: "30px" }}>
              {this.props.location.state ? (
                <div>
                  <p style={{ marginLeft: "35%" }}>
                    New Portfolio{" "}
                    <Checkbox
                      label="OverWrite Existing"
                      value={this.state.overwrite}
                      onChange={(e) =>
                        this.setState(
                          { overwrite: !this.state.overwrite },
                          () => console.log("overwrite", this.state.overwrite)
                        )
                      }
                      toggle
                    />
                  </p>
                  {this.state.overwrite ? (
                    <Form.Field
                      control={Input}
                      value={this.state.portfolio}
                      onChange={(e) =>
                        this.setState({ portfolio: e.target.value })
                      }
                    />
                  ) : (
                    <Form.Field
                      control={Input}
                      label="Portfolio"
                      defaultValue={this.props.location.state.assets.name}
                      disabled
                    />
                  )}
                </div>
              ) : (
                <div>
                  <Form.Field
                    id="form-input-control-map-portfolio"
                    control={Input}
                    value={this.state.portfolio}
                    onChange={(e) =>
                      this.setState({ portfolio: e.target.value })
                    }
                    label="Portfolio Name"
                    placeholder="Enter portfolio name (minimum 3 characters)"
                  />

                  <Label
                    style={{
                      backgroundColor: "white",
                      color: "#18202F",
                      fontSize: "14px",
                      padding: "0px",
                    }}
                  >
                    Select Variables for Analysis
                  </Label>
                  <br />
                  <Select
                    placeholder="Select Variable"
                    onChange={this.handleVariableChange}
                    style={{ width: "100%" }}
                    value={this.state.selectedVariable}
                    disabled={this.state.selectDisabled}
                  >
                    {availableDatasets.rainfall_flood === "NOT_APPLICABLE" ||
                    availableDatasets.rainfall_flood === "NOT_AVAILABLE" ||
                    availableDatasets.rainfall_flood?.length === 0 ? null : (
                      <Select.Option value="Rainfall Flood">
                        Rainfall Flood
                      </Select.Option>
                    )}
                    {availableDatasets.storm_surge === "NOT_APPLICABLE" ||
                    availableDatasets.storm_surge?.length === 0 ? null : (
                      <Select.Option value="Storm Surge">
                        Storm Surge
                      </Select.Option>
                    )}
                  </Select>

                  {selectedVariable && (
                    <div className="dataset-container">
                      <Label
                        style={{
                          backgroundColor: "white",
                          color: "#18202F",
                          fontSize: "14px",
                          padding: "0px",
                        }}
                        className="dataset-label"
                      >
                        Available Datasets for {selectedVariable}
                      </Label>

                      <div className="select-container">
                        {/* Rainfall Flood Dropdown */}
                        {selectedVariable === "Rainfall Flood" && (
                          <div className="select-item">
                            {availableDatasets.rainfall_flood ===
                              "NOT_APPLICABLE" ||
                            availableDatasets.rainfall_flood ===
                              "NOT_AVAILABLE" ||
                            availableDatasets.rainfall_flood?.length === 0 ? (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                No dataset available for Rainfall Flood.
                              </p>
                            ) : (
                              <Select
                                mode="multiple"
                                placeholder="Select Datasets"
                                className="select-dropdown"
                                onChange={(value) =>
                                  this.handleSelectChange(value)
                                }
                              >
                                {availableDatasets.rainfall_flood.map(
                                  (option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.key}
                                    </Select.Option>
                                  )
                                )}
                              </Select>
                            )}
                          </div>
                        )}

                        {/* Storm Surge Dropdown */}
                        {selectedVariable === "Storm Surge" && (
                          <div className="select-item">
                            {availableDatasets.storm_surge ===
                              "NOT_APPLICABLE" ||
                            availableDatasets.storm_surge?.length === 0 ? (
                              <p style={{ color: "red", fontSize: "14px" }}>
                                No dataset available for Storm Surge.
                              </p>
                            ) : (
                              <Select
                                mode="multiple"
                                placeholder="Select Datasets"
                                className="select-dropdown"
                                onChange={(value) =>
                                  this.handleSelectChange(value)
                                }
                              >
                                {availableDatasets.storm_surge.map((option) => (
                                  <Select.Option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.key}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Conditional rendering of Distance Options 
                  {selections.length > 0 && (
                    <div className="distance-container">
                      <Label
                        style={{
                          backgroundColor: "white",
                          color: "#18202F",
                          fontSize: "14px",
                          padding: "0px",
                          marginTop: "10px",
                        }}
                      >
                        Distance Options
                      </Label>
                      <Select
                        placeholder="Select Distance"
                        style={{ width: "100%" }}
                        value={distance}
                        onChange={this.handleDistanceChange}
                      >
                        {["1", "3", "5", "50"].map((option) => (
                          <Select.Option key={option} value={option}>
                            {option} km
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}*/}
                </div>
              )}

              <br />

              {this.state.loading && !this.props.errors.error ? (
                <div
                  className="map_loader"
                  style={{ textAlign: "center", marginTop: "0px" }}
                >
                  <Spin tip="Generating Map..." size="large" />
                </div>
              ) : (
                <Button
                  disabled={this.isSubmitDisabled()}
                  style={{
                    backgroundColor: "#18202F",
                    marginLeft: "42.5%",
                    color: "white",
                  }}
                  onClick={this.handleOpenConfirmation}
                  primary
                >
                  Submit
                </Button>
              )}
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.locus.errors,
    locus: state.locus,
    company: state.company.company,
    permissions: state.locus.permissions,
    name: state.locus.name,
    point: state.locus.point,
    indices: state.locus.indices,
    transition: state.locus.transition,
    company_name: state.locus.company_name,
    company_location: state.locus.company_location,
    auth: state.auth,
    map: state.locus.map,
    dataset: state.locus.dataset,
  };
};
const mapDispatchToPros = (dispatch) => {
  return {
    createMap: (formdata) => {
      dispatch(locus.createMap(formdata));
    },
    logout: () => {
      dispatch(auth.logout());
    },
    fetchdataset: (formdata) => {
      dispatch(locus.Dataset(formdata));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToPros)(CreateMap);
