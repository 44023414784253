import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Icon, Button } from "semantic-ui-react";
import { Progress } from "antd";

const Progress_bar = (props) => {
  console.log("Progress Hazard", props.analysis);

  let progress_bar_acute = [];
  let progress_bar_chronic = [];

  for (let i = 0; i < props.variable.length; i++) {
    if (
      props.hazard.acute[props.variable[i]] &&
      props.hazard.acute[props.variable[i]][0] >= 0
    ) {
      console.log("Acute", props.hazard.acute[props.variable[i]]);
      progress_bar_acute.push(
        <div className="Chronic Progress">
          <p style={{ fontSize: "13px" }}>
            {props.variable[i]}
            {props.hazard.acute ? (
              <b style={{ float: "right" }}>
                {props.hazard.acute[props.variable[i]][0]}
                {props.hazard.acute[props.variable[i]][1]}
              </b>
            ) : null}
          </p>
          {props.newdata.acute[props.variable[i]] == 0 ? (
            <Progress percent={0} className="lessrisk" />
          ) : (
            <Progress
              percent={props.newdata.acute[props.variable[i]][2]}
              className="lessrisk"
            />
          )}

          <Progress
            percent={props.hazard.acute[props.variable[i]][2]}
            color="green"
          />
        </div>
      );
    }
  }
  for (let i = 0; i < props.variable.length; i++) {
    if (
      props.hazard.chronic[props.variable[i]] &&
      props.hazard.chronic[props.variable[i]][0] >= 0
    ) {
      progress_bar_chronic.push(
        <div className="Chronic Progress">
          <p style={{ fontSize: "13px" }}>
            {props.variable[i]}
            <b style={{ float: "right" }}>
              {props.hazard.chronic[props.variable[i]][0]}{" "}
              {props.hazard.chronic[props.variable[i]][1]}
            </b>
          </p>
          {props.newdata.chronic[props.variable[i]] == 0 ? (
            <Progress percent={0} className="lessrisk" />
          ) : (
            <Progress
              percent={props.newdata.chronic[props.variable[i]][2]}
              className="lessrisk"
            />
          )}

          <Progress
            percent={props.hazard.chronic[props.variable[i]][2]}
            color="green"
          />
        </div>
      );
    }
  }
  console.log("progess", progress_bar_acute, progress_bar_chronic);

  return (
    <Tabs
      defaultActiveKey="All"
      style={{ padding: "5px", borderBottom: "0px", fontSize: "13px" }}
    >
      <Tab title="All" eventKey="All">
        {props.hazard.both ? (
          <div>
            {props.variable.indexOf("Rainfall Flood") > -1 ? (
              <div className="Progress">
                {" "}
                <p style={{ fontSize: "13px" }}>
                  Rainfall Flood
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Rainfall Flood"][0]}{" "}
                    {props.hazard.both["Rainfall Flood"][1]}&nbsp;
                    {props.hazard.both["Rainfall Flood"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Rainfall Flood"][2] > 0 &&
                      props.hazard.both["Rainfall Flood"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Rainfall Flood"][2] >= 25 &&
                      props.hazard.both["Rainfall Flood"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Rainfall Flood"][2] >= 50 &&
                      props.hazard.both["Rainfall Flood"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Rainfall Flood"][2] >= 75 &&
                      props.hazard.both["Rainfall Flood"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Rainfall Flood"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Rainfall Flood"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Rainfall Flood"][0]} m`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Rainfall Flood"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Rainfall Flood"][0]} m`
                    }
                  />
                )}
                <Progress
                  percent={props.hazard.both["Rainfall Flood"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Rainfall Flood"][0]} m`
                  }
                />
              </div>
            ) : null}
            {props.variable.indexOf("River Flood") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  River Flood
                  <b style={{ float: "right" }}>
                    {props.hazard.both["River Flood"][0]}{" "}
                    {props.hazard.both["River Flood"][1]}&nbsp;
                    {props.hazard.both["River Flood"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["River Flood"][2] > 0 &&
                      props.hazard.both["River Flood"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["River Flood"][2] >= 25 &&
                      props.hazard.both["River Flood"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["River Flood"][2] >= 50 &&
                      props.hazard.both["River Flood"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["River Flood"][2] >= 75 &&
                      props.hazard.both["River Flood"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["River Flood"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["River Flood"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["River Flood"][0]} m`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["River Flood"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["River Flood"][0]} m`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["River Flood"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["River Flood"][0]} m`
                  }
                />
              </div>
            ) : null}
            {props.variable.indexOf("Storm Surge") > -1 ? (
              <div className="Progress">
                {" "}
                <p style={{ fontSize: "13px" }}>
                  Storm Surge
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Storm Surge"][0]}{" "}
                    {props.hazard.both["Storm Surge"][1]}&nbsp;
                    {props.hazard.both["Storm Surge"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Storm Surge"][2] > 0 &&
                      props.hazard.both["Storm Surge"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Storm Surge"][2] >= 25 &&
                      props.hazard.both["Storm Surge"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Storm Surge"][2] >= 50 &&
                      props.hazard.both["Storm Surge"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Storm Surge"][2] >= 75 &&
                      props.hazard.both["Storm Surge"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Storm Surge"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Storm Surge"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Storm Surge"][0]} m`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Storm Surge"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Storm Surge"][0]} m`
                    }
                  />
                )}
                <Progress
                  percent={props.hazard.both["Storm Surge"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Storm Surge"][0]} m`
                  }
                />
              </div>
            ) : null}
            {props.variable.indexOf("Typhoon") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  Typhoon
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Typhoon"][0]}{" "}
                    {props.hazard.both["Typhoon"][1]}&nbsp;
                    {props.hazard.both["Typhoon"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Typhoon"][2] > 0 &&
                      props.hazard.both["Typhoon"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Typhoon"][2] >= 25 &&
                      props.hazard.both["Typhoon"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Typhoon"][2] >= 50 &&
                      props.hazard.both["Typhoon"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Typhoon"][2] >= 75 &&
                      props.hazard.both["Typhoon"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Typhoon"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Typhoon"] == 0 ? (
                  <Progress percent={0} className="lessrisk" />
                ) : (
                  <Progress
                    percent={props.newdata.both["Typhoon"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Typhoon"][0]} km/h`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Typhoon"][2]}
                  className="risk"
                  format={(percent) =>
                    `${props.hazard.both["Typhoon"][0]} km/h`
                  }
                />
              </div>
            ) : null}

            {props.variable.indexOf("Landslide") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  LandSlide
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Landslide"][0]}&nbsp;
                    {props.hazard.both["Landslide"][1]}
                    {props.hazard.both["Landslide"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Landslide"][2] > 0 &&
                      props.hazard.both["Landslide"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Landslide"][2] >= 25 &&
                      props.hazard.both["Landslide"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Landslide"][2] >= 50 &&
                      props.hazard.both["Landslide"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium High]
                      </span>
                    ) : props.hazard.both["Landslide"][2] >= 75 &&
                      props.hazard.both["Landslide"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Landslide"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        Very [High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Landslide"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Landslide"][0]} Ann. Freq.`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Landslide"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Landslide"][0]} Ann. Freq.`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Landslide"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Landslide"][0]} Ann. Freq.`
                  }
                />
              </div>
            ) : null}

            {props.variable.indexOf("Extreme Heat") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  Extreme heat
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Extreme heat"][0]}{" "}
                    {props.hazard.both["Extreme heat"][1]}&nbsp;
                    {props.hazard.both["Extreme heat"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Extreme heat"][2] > 0 &&
                      props.hazard.both["Extreme heat"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Extreme heat"][2] >= 25 &&
                      props.hazard.both["Extreme heat"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Extreme heat"][2] >= 50 &&
                      props.hazard.both["Extreme heat"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Extreme heat"][2] >= 75 &&
                      props.hazard.both["Extreme heat"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Extreme heat"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Extreme heat"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Extreme heat"][0]}${props.newdata.both["Extreme heat"][1]}`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Extreme heat"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Extreme heat"][0]}${props.newdata.both["Extreme heat"][1]}`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Extreme heat"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Extreme heat"][0]}${props.newdata.both["Extreme heat"][1]}`
                  }
                />
              </div>
            ) : null}
            {props.variable.indexOf("Extreme Cold") > -1 &&
            props.analysis == "ssp" ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  Extreme Cold
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Extreme Cold"][0]}{" "}
                    {props.hazard.both["Extreme Cold"][1]}&nbsp;
                    {props.hazard.both["Extreme Cold"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Extreme Cold"][2] > 0 &&
                      props.hazard.both["Extreme Cold"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Extreme Cold"][2] >= 25 &&
                      props.hazard.both["Extreme Cold"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Extreme Cold"][2] >= 50 &&
                      props.hazard.both["Extreme Cold"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Extreme Cold"][2] >= 75 &&
                      props.hazard.both["Extreme Cold"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Extreme Cold"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Extreme Cold"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Extreme Cold"][0]}${props.newdata.both["Extreme Cold"][1]}`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Extreme Cold"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Extreme Cold"][0]}${props.newdata.both["Extreme Cold"][1]}`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Extreme Cold"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Extreme Cold"][0]}${props.newdata.both["Extreme Cold"][1]}`
                  }
                />
              </div>
            ) : null}
            {props.variable.indexOf("Snow Fall") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  SnowFall
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Snow Fall"][0]}{" "}
                    {props.hazard.both["Snow Fall"][1]}&nbsp;
                    {props.hazard.both["Snow Fall"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Snow Fall"][2] > 0 &&
                      props.hazard.both["Snow Fall"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Snow Fall"][2] >= 25 &&
                      props.hazard.both["Snow Fall"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Snow Fall"][2] >= 50 &&
                      props.hazard.both["Snow Fall"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Snow Fall"][2] >= 75 &&
                      props.hazard.both["Snow Fall"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Snow Fall"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Snow Fall"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) => (
                      <span>{props.newdata.both["Snow Fall"][0]} mm/year</span>
                    )}
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Snow Fall"][2]}
                    className="lessrisk"
                    format={(percent) => (
                      <span>{props.newdata.both["Snow Fall"][0]} mm/year</span>
                    )}
                  />
                )}

                <Progress
                  percent={props.hazard.both["Snow Fall"][2]}
                  color="green"
                  format={(percent) => (
                    <span>{props.newdata.both["Snow Fall"][0]} mm/year</span>
                  )}
                />
              </div>
            ) : null}
            {props.variable.indexOf("Sea Level Rise") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  Sea Level Rise
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Sea Level Rise"][0]}{" "}
                    {props.hazard.both["Sea Level Rise"][1]}&nbsp;
                    {props.hazard.both["Sea Level Rise"][2] > 0 == 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Sea Level Rise"][2] > 0 &&
                      props.hazard.both["Sea Level Rise"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Sea Level Rise"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Sea Level Rise"][0]} m`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Sea Level Rise"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Sea Level Rise"][0]} m`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Sea Level Rise"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Sea Level Rise"][0]} m`
                  }
                />
              </div>
            ) : null}
            {props.variable.indexOf("Drought") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  Drought
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Drought"][0]}{" "}
                    {props.hazard.both["Drought"][1]}&nbsp;
                    {props.hazard.both["Drought"][2] <= 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Drought"][2] > 0 &&
                      props.hazard.both["Drought"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Drought"][2] >= 25 &&
                      props.hazard.both["Drought"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Drought"][2] >= 50 &&
                      props.hazard.both["Drought"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium-High]
                      </span>
                    ) : props.hazard.both["Drought"][2] >= 75 &&
                      props.hazard.both["Drought"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Drought"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Very High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Drought"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Drought"][0]} SPI`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Drought"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Drought"][0]} SPI`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Drought"][2]}
                  color="green"
                  format={(percent) => `${props.hazard.both["Drought"][0]} SPI`}
                />
              </div>
            ) : null}
            {props.variable.indexOf("Wild Fire") > -1 ? (
              <div className="Progress">
                <p style={{ fontSize: "13px" }}>
                  Wild Fire
                  <b style={{ float: "right" }}>
                    {props.hazard.both["Wild Fire"][0]}{" "}
                    {props.hazard.both["Wild Fire"][1]}
                    {props.hazard.both["Wild Fire"][2] <= 0 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [No Risk]
                      </span>
                    ) : props.hazard.both["Wild Fire"][2] > 0 &&
                      props.hazard.both["Wild Fire"][2] < 25 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Low]
                      </span>
                    ) : props.hazard.both["Wild Fire"][2] >= 25 &&
                      props.hazard.both["Wild Fire"][2] < 50 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [Medium]
                      </span>
                    ) : props.hazard.both["Wild Fire"][2] >= 50 &&
                      props.hazard.both["Wild Fire"][2] < 75 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [[Medium]-[High]]
                      </span>
                    ) : props.hazard.both["Wild Fire"][2] >= 75 &&
                      props.hazard.both["Wild Fire"][2] < 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        [High]
                      </span>
                    ) : props.hazard.both["Wild Fire"][2] >= 100 ? (
                      <span style={{ color: "#18202F", fontSize: "14px" }}>
                        Very [High]
                      </span>
                    ) : null}
                  </b>
                </p>
                {props.newdata.both["Wild Fire"] == 0 ? (
                  <Progress
                    percent={0}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Wild Fire"][0]} ${props.newdata.both["Wild Fire"][1]}`
                    }
                  />
                ) : (
                  <Progress
                    percent={props.newdata.both["Wild Fire"][2]}
                    className="lessrisk"
                    format={(percent) =>
                      `${props.newdata.both["Wild Fire"][0]} ${props.newdata.both["Wild Fire"][1]}`
                    }
                  />
                )}

                <Progress
                  percent={props.hazard.both["Wild Fire"][2]}
                  color="green"
                  format={(percent) =>
                    `${props.hazard.both["Wild Fire"][0]} ${props.hazard.both["Wild Fire"][1]}`
                  }
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </Tab>
      <Tab title="Chronic" eventKey="Chronic">
        {props.variable.indexOf("Extreme Heat") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              Extreme heat
              <b style={{ float: "right" }}>
                {props.hazard.both["Extreme heat"][0]}{" "}
                {props.hazard.both["Extreme heat"][1]}&nbsp;
                {props.hazard.both["Extreme heat"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Extreme heat"][2] > 0 &&
                  props.hazard.both["Extreme heat"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Extreme heat"][2] >= 25 &&
                  props.hazard.both["Extreme heat"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Extreme heat"][2] >= 50 &&
                  props.hazard.both["Extreme heat"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Extreme heat"][2] >= 75 &&
                  props.hazard.both["Extreme heat"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Extreme heat"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Extreme heat"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Extreme heat"][0]}${props.newdata.both["Extreme heat"][1]}`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Extreme heat"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Extreme heat"][0]}${props.newdata.both["Extreme heat"][1]}`
                }
              />
            )}

            <Progress
              percent={props.hazard.both["Extreme heat"][2]}
              color="green"
              format={(percent) =>
                `${props.hazard.both["Extreme heat"][0]}${props.newdata.both["Extreme heat"][1]}`
              }
            />
          </div>
        ) : null}
        {props.variable.indexOf("Snow Fall") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              SnowFall
              <b style={{ float: "right" }}>
                {props.hazard.both["Snow Fall"][0]}{" "}
                {props.hazard.both["Snow Fall"][1]}&nbsp;
                {props.hazard.both["Snow Fall"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Snow Fall"][2] > 0 &&
                  props.hazard.both["Snow Fall"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Snow Fall"][2] >= 25 &&
                  props.hazard.both["Snow Fall"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Snow Fall"][2] >= 50 &&
                  props.hazard.both["Snow Fall"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Snow Fall"][2] >= 75 &&
                  props.hazard.both["Snow Fall"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Snow Fall"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Snow Fall"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) => (
                  <span>{props.newdata.both["Snow Fall"][0]} mm/year</span>
                )}
              />
            ) : (
              <Progress
                percent={props.newdata.both["Snow Fall"][2]}
                className="lessrisk"
                format={(percent) => (
                  <span>{props.newdata.both["Snow Fall"][0]} mm/year</span>
                )}
              />
            )}

            <Progress
              percent={props.hazard.both["Snow Fall"][2]}
              color="green"
              format={(percent) => (
                <span>{props.newdata.both["Snow Fall"][0]} mm/year</span>
              )}
            />
          </div>
        ) : null}
        {props.variable.indexOf("Sea Level Rise") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              Sea Level Rise
              <b style={{ float: "right" }}>
                {props.hazard.both["Sea Level Rise"][0]}{" "}
                {props.hazard.both["Sea Level Rise"][1]}&nbsp;
                {props.hazard.both["Sea Level Rise"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Sea Level Rise"][2] > 0 &&
                  props.hazard.both["Sea Level Rise"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Sea Level Rise"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Sea Level Rise"][0]} m`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Sea Level Rise"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Sea Level Rise"][0]} m`
                }
              />
            )}

            <Progress
              percent={props.hazard.both["Sea Level Rise"][2]}
              color="green"
              format={(percent) =>
                `${props.hazard.both["Sea Level Rise"][0]} m`
              }
            />
          </div>
        ) : null}
        {props.variable.indexOf("Drought") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              Drought
              <b style={{ float: "right" }}>
                {props.hazard.both["Drought"][0]}{" "}
                {props.hazard.both["Drought"][1]}&nbsp;
                {props.hazard.both["Drought"][2] <= 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Drought"][2] > 0 &&
                  props.hazard.both["Drought"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Drought"][2] >= 25 &&
                  props.hazard.both["Drought"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Drought"][2] >= 50 &&
                  props.hazard.both["Drought"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Drought"][2] >= 75 &&
                  props.hazard.both["Drought"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Drought"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Drought"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) => `${props.newdata.both["Drought"][0]} SPI`}
              />
            ) : (
              <Progress
                percent={props.newdata.both["Drought"][2]}
                className="lessrisk"
                format={(percent) => `${props.newdata.both["Drought"][0]} SPI`}
              />
            )}

            <Progress
              percent={props.hazard.both["Drought"][2]}
              color="green"
              format={(percent) => `${props.hazard.both["Drought"][0]} SPI`}
            />
          </div>
        ) : null}
        {props.variable.indexOf("Landslide") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              LandSlide
              <b style={{ float: "right" }}>
                {props.hazard.both["Landslide"][0]}&nbsp;
                {props.hazard.both["Landslide"][1]}
                {props.hazard.both["Landslide"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Landslide"][2] > 0 &&
                  props.hazard.both["Landslide"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Landslide"][2] >= 25 &&
                  props.hazard.both["Landslide"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Landslide"][2] >= 50 &&
                  props.hazard.both["Landslide"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium High]
                  </span>
                ) : props.hazard.both["Landslide"][2] >= 75 &&
                  props.hazard.both["Landslide"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Landslide"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    Very [High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Landslide"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Landslide"][0]} Ann. Freq.`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Landslide"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Landslide"][0]} Ann. Freq.`
                }
              />
            )}

            <Progress
              percent={props.hazard.both["Landslide"][2]}
              color="green"
              format={(percent) =>
                `${props.hazard.both["Landslide"][0]} Ann. Freq.`
              }
            />
          </div>
        ) : null}

        {/* {progress_bar_chronic ? <div>{progress_bar_chronic}</div> : null}*/}
      </Tab>
      <Tab title="Acute" eventKey="Acute">
        {props.variable.indexOf("Rainfall Flood") > -1 ? (
          <div className="Progress">
            {" "}
            <p style={{ fontSize: "13px" }}>
              Rainfall Flood
              <b style={{ float: "right" }}>
                {props.hazard.both["Rainfall Flood"][0]}{" "}
                {props.hazard.both["Rainfall Flood"][1]}&nbsp;
                {props.hazard.both["Rainfall Flood"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Rainfall Flood"][2] > 0 &&
                  props.hazard.both["Rainfall Flood"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Rainfall Flood"][2] >= 25 &&
                  props.hazard.both["Rainfall Flood"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Rainfall Flood"][2] >= 50 &&
                  props.hazard.both["Rainfall Flood"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Rainfall Flood"][2] >= 75 &&
                  props.hazard.both["Rainfall Flood"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Rainfall Flood"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Rainfall Flood"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Rainfall Flood"][0]} m`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Rainfall Flood"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Rainfall Flood"][0]} m`
                }
              />
            )}
            <Progress
              percent={props.hazard.both["Rainfall Flood"][2]}
              color="green"
              format={(percent) =>
                `${props.hazard.both["Rainfall Flood"][0]} m`
              }
            />
          </div>
        ) : null}
        {props.variable.indexOf("River Flood") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              River Flood
              <b style={{ float: "right" }}>
                {props.hazard.both["River Flood"][0]}{" "}
                {props.hazard.both["River Flood"][1]}&nbsp;
                {props.hazard.both["River Flood"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["River Flood"][2] > 0 &&
                  props.hazard.both["River Flood"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["River Flood"][2] >= 25 &&
                  props.hazard.both["River Flood"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["River Flood"][2] >= 50 &&
                  props.hazard.both["River Flood"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["River Flood"][2] >= 75 &&
                  props.hazard.both["River Flood"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["River Flood"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["River Flood"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["River Flood"][0]} m`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["River Flood"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["River Flood"][0]} m`
                }
              />
            )}

            <Progress
              percent={props.hazard.both["River Flood"][2]}
              color="green"
              format={(percent) => `${props.hazard.both["River Flood"][0]} m`}
            />
          </div>
        ) : null}
        {props.variable.indexOf("Storm Surge") > -1 ? (
          <div className="Progress">
            {" "}
            <p style={{ fontSize: "13px" }}>
              Storm Surge
              <b style={{ float: "right" }}>
                {props.hazard.both["Storm Surge"][0]}{" "}
                {props.hazard.both["Storm Surge"][1]}&nbsp;
                {props.hazard.both["Storm Surge"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Storm Surge"][2] > 0 &&
                  props.hazard.both["Storm Surge"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Storm Surge"][2] >= 25 &&
                  props.hazard.both["Storm Surge"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Storm Surge"][2] >= 50 &&
                  props.hazard.both["Storm Surge"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Storm Surge"][2] >= 75 &&
                  props.hazard.both["Storm Surge"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Storm Surge"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Storm Surge"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Storm Surge"][0]} m`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Storm Surge"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Storm Surge"][0]} m`
                }
              />
            )}
            <Progress
              percent={props.hazard.both["Storm Surge"][2]}
              color="green"
              format={(percent) => `${props.hazard.both["Storm Surge"][0]} m`}
            />
          </div>
        ) : null}
        {props.variable.indexOf("Typhoon") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              Typhoon
              <b style={{ float: "right" }}>
                {props.hazard.both["Typhoon"][0]}{" "}
                {props.hazard.both["Typhoon"][1]}&nbsp;
                {props.hazard.both["Typhoon"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Typhoon"][2] > 0 &&
                  props.hazard.both["Typhoon"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Typhoon"][2] >= 25 &&
                  props.hazard.both["Typhoon"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Typhoon"][2] >= 50 &&
                  props.hazard.both["Typhoon"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Typhoon"][2] >= 75 &&
                  props.hazard.both["Typhoon"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Typhoon"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Typhoon"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.both["Typhoon"][2]}
                className="lessrisk"
                format={(percent) => `${props.newdata.both["Typhoon"][0]} km/h`}
              />
            )}

            <Progress
              percent={props.hazard.both["Typhoon"][2]}
              className="risk"
              format={(percent) => `${props.hazard.both["Typhoon"][0]} km/h`}
            />
          </div>
        ) : null}

        {props.variable.indexOf("Wild Fire") > -1 ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              Wild Fire
              <b style={{ float: "right" }}>
                {props.hazard.both["Wild Fire"][0]}{" "}
                {props.hazard.both["Wild Fire"][1]}
                {props.hazard.both["Wild Fire"][2] <= 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Wild Fire"][2] > 0 &&
                  props.hazard.both["Wild Fire"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Wild Fire"][2] >= 25 &&
                  props.hazard.both["Wild Fire"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Wild Fire"][2] >= 50 &&
                  props.hazard.both["Wild Fire"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [[Medium]-[High]]
                  </span>
                ) : props.hazard.both["Wild Fire"][2] >= 75 &&
                  props.hazard.both["Wild Fire"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Wild Fire"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    Very [High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Wild Fire"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Wild Fire"][0]} ${props.newdata.both["Wild Fire"][1]}`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Wild Fire"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Wild Fire"][0]} ${props.newdata.both["Wild Fire"][1]}`
                }
              />
            )}

            <Progress
              percent={props.hazard.both["Wild Fire"][2]}
              color="green"
              format={(percent) =>
                `${props.hazard.both["Wild Fire"][0]} ${props.hazard.both["Wild Fire"][1]}`
              }
            />
          </div>
        ) : null}

        {props.variable.indexOf("Extreme Cold") > -1 &&
        props.analysis == "ssp" ? (
          <div className="Progress">
            <p style={{ fontSize: "13px" }}>
              Extreme Cold
              <b style={{ float: "right" }}>
                {props.hazard.both["Extreme Cold"][0]}{" "}
                {props.hazard.both["Extreme Cold"][1]}&nbsp;
                {props.hazard.both["Extreme Cold"][2] > 0 == 0 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [No Risk]
                  </span>
                ) : props.hazard.both["Extreme Cold"][2] > 0 &&
                  props.hazard.both["Extreme Cold"][2] < 25 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Low]
                  </span>
                ) : props.hazard.both["Extreme Cold"][2] >= 25 &&
                  props.hazard.both["Extreme Cold"][2] < 50 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium]
                  </span>
                ) : props.hazard.both["Extreme Cold"][2] >= 50 &&
                  props.hazard.both["Extreme Cold"][2] < 75 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Medium-High]
                  </span>
                ) : props.hazard.both["Extreme Cold"][2] >= 75 &&
                  props.hazard.both["Extreme Cold"][2] < 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [High]
                  </span>
                ) : props.hazard.both["Extreme Cold"][2] >= 100 ? (
                  <span style={{ color: "#18202F", fontSize: "14px" }}>
                    [Very High]
                  </span>
                ) : null}
              </b>
            </p>
            {props.newdata.both["Extreme Cold"] == 0 ? (
              <Progress
                percent={0}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Extreme Cold"][0]}${props.newdata.both["Extreme Cold"][1]}`
                }
              />
            ) : (
              <Progress
                percent={props.newdata.both["Extreme Cold"][2]}
                className="lessrisk"
                format={(percent) =>
                  `${props.newdata.both["Extreme Cold"][0]}${props.newdata.both["Extreme Cold"][1]}`
                }
              />
            )}

            <Progress
              percent={props.hazard.both["Extreme Cold"][2]}
              color="green"
              format={(percent) =>
                `${props.hazard.both["Extreme Cold"][0]}${props.newdata.both["Extreme Cold"][1]}`
              }
            />
          </div>
        ) : null}

        {/* {progress_bar_acute ? <div>{progress_bar_acute}</div> : null}*/}
      </Tab>
      {/*  {props.hazard["mitigation"] ? (
        <Tab title="Mitigation" eventKey="mitigation">
          {props.variable.indexOf("River Flood") > -1 ? (
            <div className="Progress">
              <p style={{ fontSize: "13px" }}>
                River Flood
                <b style={{ float: "right" }}>
                  {props.hazard.mitigation["River Flood"][0]}{" "}
                  {props.hazard.mitigation["River Flood"][1]}&nbsp;
                  {props.hazard.mitigation["River Flood"][2] > 0 == 0 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [No Risk]
                    </span>
                  ) : props.hazard.mitigation["River Flood"][2] > 0 &&
                    props.hazard.mitigation["River Flood"][2] < 25 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Low]
                    </span>
                  ) : props.hazard.mitigation["River Flood"][2] >= 25 &&
                    props.hazard.mitigation["River Flood"][2] < 50 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Medium]
                    </span>
                  ) : props.hazard.mitigation["River Flood"][2] >= 50 &&
                    props.hazard.mitigation["River Flood"][2] < 75 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Medium-High]
                    </span>
                  ) : props.hazard.mitigation["River Flood"][2] >= 75 &&
                    props.hazard.mitigation["River Flood"][2] < 100 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [High]
                    </span>
                  ) : props.hazard.mitigation["River Flood"][2] >= 100 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Very High]
                    </span>
                  ) : null}
                </b>
              </p>
              {props.hazard.both["River Flood"] == 0 ? (
                <Progress
                  percent={0}
                  className="lessrisk"
                  format={(percent) =>
                    `${props.hazard.both["River Flood"][0]} m`
                  }
                />
              ) : (
                <Progress
                  percent={props.hazard.both["River Flood"][2]}
                  className="lessrisk"
                  format={(percent) =>
                    `${props.hazard.both["River Flood"][0]} m`
                  }
                />
              )}

              <Progress
                percent={props.hazard.mitigation["River Flood"][2]}
                color="green"
                format={(percent) =>
                  `${props.hazard.mitigation["River Flood"][0]} m`
                }
              />
            </div>
          ) : null}
          {props.variable.indexOf("Rainfall Flood") > -1 ? (
            <div className="Progress">
              <p style={{ fontSize: "13px" }}>
                River Flood
                <b style={{ float: "right" }}>
                  {props.hazard.mitigation["Rainfall Flood"][0]}{" "}
                  {props.hazard.mitigation["Rainfall Flood"][1]}&nbsp;
                  {props.hazard.mitigation["Rainfall Flood"][2] > 0 == 0 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [No Risk]
                    </span>
                  ) : props.hazard.mitigation["Rainfall Flood"][2] > 0 &&
                    props.hazard.mitigation["Rainfall Flood"][2] < 25 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Low]
                    </span>
                  ) : props.hazard.mitigation["Rainfall Flood"][2] >= 25 &&
                    props.hazard.mitigation["Rainfall Flood"][2] < 50 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Medium]
                    </span>
                  ) : props.hazard.mitigation["Rainfall Flood"][2] >= 50 &&
                    props.hazard.mitigation["Rainfall Flood"][2] < 75 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Medium-High]
                    </span>
                  ) : props.hazard.mitigation["Rainfall Flood"][2] >= 75 &&
                    props.hazard.mitigation["Rainfall Flood"][2] < 100 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [High]
                    </span>
                  ) : props.hazard.mitigation["Rainfall Flood"][2] >= 100 ? (
                    <span style={{ color: "#18202F", fontSize: "14px" }}>
                      [Very High]
                    </span>
                  ) : null}
                </b>
              </p>
              {props.hazard.both["Rainfall Flood"] == 0 ? (
                <Progress
                  percent={0}
                  className="lessrisk"
                  format={(percent) =>
                    `${props.hazard.both["Rainfall Flood"][0]} m`
                  }
                />
              ) : (
                <Progress
                  percent={props.hazard.both["Rainfall Flood"][2]}
                  className="lessrisk"
                  format={(percent) =>
                    `${props.hazard.both["Rainfall Flood"][0]} m`
                  }
                />
              )}

              <Progress
                percent={props.hazard.mitigation["Rainfall Flood"][2]}
                color="green"
                format={(percent) =>
                  `${props.hazard.mitigation["Rainfall Flood"][0]} m`
                }
              />
            </div>
          ) : null}
        </Tab>
              ) : null}*/}

      {/*   {props.data ? (
        <Tab title="Energy" eventKey="energy">
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Solar Production
              <b style={{ float: "right" }}>{props.data.energy["solar"]}%</b>
            </p>
            {props.newdata.energy["solar"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.energy["solar"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.energy["solar"]} color="green" />
          </div>
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Wind Production
              <b style={{ float: "right" }}>{props.data.energy["wind"]}%</b>
            </p>
            {props.newdata.energy["wind"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.energy["wind"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.energy["wind"]} color="green" />
          </div>
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Hydro Production
              <b style={{ float: "right" }}>{props.data.energy["hydro"]}%</b>
            </p>
            {props.newdata.energy["hydro"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.energy["hydro"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.energy["hydro"]} color="green" />
          </div>
        </Tab>
      ) : null}
      {props.data ? (
        <Tab title="Agriculture" eventKey="agriculture">
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Wheat Production
              <b style={{ float: "right" }}>
                {props.data.agriculture["wheat"]}%
              </b>
            </p>
            {props.newdata.agriculture["wheat"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.agriculture["wheat"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.agriculture["wheat"]} color="green" />
          </div>
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Rice Production
              <b style={{ float: "right" }}>
                {props.data.agriculture["rice"]}%
              </b>
            </p>
            {props.newdata.agriculture["rice"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.agriculture["rice"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.agriculture["rice"]} color="green" />
          </div>
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Maize Production
              <b style={{ float: "right" }}>
                {props.data.agriculture["maize"]}%
              </b>
            </p>
            {props.newdata.agriculture["maize"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.agriculture["maize"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.agriculture["maize"]} color="green" />
          </div>
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Fruit Production
              <b style={{ float: "right" }}>
                {props.data.agriculture["fruit"]}%
              </b>
            </p>
            {props.newdata.agriculture["fruit"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.agriculture["fruit"]}
                className="lessrisk"
              />
            )}

            <Progress percent={props.data.agriculture["fruit"]} color="green" />
          </div>
          <div className="Chronic">
            <p style={{ fontSize: "13px" }}>
              Vegetable Production
              <b style={{ float: "right" }}>
                {props.data.agriculture["vegetable"]}%
              </b>
            </p>
            {props.newdata.agriculture["vegetable"] == 0 ? (
              <Progress percent={0} className="lessrisk" />
            ) : (
              <Progress
                percent={props.newdata.agriculture["vegetable"]}
                className="lessrisk"
              />
            )}

            <Progress
              percent={props.data.agriculture["vegetable"]}
              color="green"
            />
          </div>
        </Tab>
            ) : null}*/}
    </Tabs>
  );
};

export default Progress_bar;
