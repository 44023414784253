import React, { useState, useEffect } from "react";
import whiteswan from "../assets/logo1.png";
import { auth } from "../actions";
import { connect } from "react-redux";
import logo from "../assets/logo.png";
import Profile from "../assets/profile.png";
import "./navmenu.css";
import {
  Navbar,
  Nav,
  Container,
  Breadcrumb,
  Row,
  NavDropdown,
} from "react-bootstrap";
import { Button } from "semantic-ui-react";

const TopNavbar = ({ logout }) => {
  const [tierData, setTierData] = useState(null);

  useEffect(() => {
    // Fetch tier data from localStorage
    const tierDataFromLocalStorage = localStorage.getItem("tier_data");
    if (tierDataFromLocalStorage) {
      try {
        const parsedTierData = JSON.parse(tierDataFromLocalStorage);
        setTierData(parsedTierData);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []);

  // API URL for updating last accessed
  const API_URL = "https://devapi.intensel.net/api/user/update_last_accessed/";

  // Function to fetch update
  const fetchLastAccessed = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.warn("No token found. User might not be logged in.");
      return;
    }

    try {
      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      console.log("Last accessed updated successfully:", data);
    } catch (error) {
      console.error("Error updating last accessed:", error);
    }
  };

  useEffect(() => {
    // Fetch immediately on mount
    fetchLastAccessed();

    // Set interval to fetch every 5 minutes (300000 milliseconds)
    const intervalId = setInterval(fetchLastAccessed, 300000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  console.log("tier", tierData);

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <Navbar collpaseOnSelect fixed="top" expand="sm" bg="dark" variant="dark">
        <Container fluid>
          <Row>
            <Navbar.Brand style={{ padding: "0.5em" }}>
              <img src={logo} height="40" width="160" alt="Logo" />
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="topnav">
                <Nav.Link href="/project">Projects</Nav.Link>
                <NavDropdown id="modulesdd" title="Modules">
                  <NavDropdown.Item href="/location">
                    Physical Risk
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/transition">
                    Transition Risk
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/create-map">
                    Map Module
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown id="documents" title="Documents">
                  <NavDropdown.Item href="/methodology">
                    Methodology
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://docs.intensel.net/">
                    APIDocs
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/changelog">
                    ChangeLogs
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/faq">Faqs</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    errors: state.auth.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(auth.logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);
