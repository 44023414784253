import React, { useState, useCallback, useEffect, Component } from "react";
import { Icon, Button, Table } from "semantic-ui-react";
import { Grid } from "semantic-ui-react";
import "./methodology.css";
import {
  Tabs,
  Tab,
  Row,
  Nav,
  Col,
  Container,
  Breadcrumb,
} from "react-bootstrap";
import "./faqs.css";
import ct1 from "../assets/ct_1.png";
import ct2 from "../assets/ct_2.png";
import gp1 from "../assets/gp 1.png";
import gp2 from "../assets/gp 2.png";
import rf1 from "../assets/rf_1.png";
import rf2 from "../assets/rf_2.png";
import rf3 from "../assets/rf_3.png";
import rf4 from "../assets/rf_4.png";
import rf5 from "../assets/rf_5.png";
import rf6 from "../assets/rf_6.png";
import rf7 from "../assets/rf_7.png";
import rf8 from "../assets/rf_8.png";
import ss1 from "../assets/ss_1.png";
import ss2 from "../assets/ss_2.png";
import ss3 from "../assets/ss_3.png";
import ss4 from "../assets/ss_4.png";
import tp1 from "../assets/tp_1.png";
import tp2 from "../assets/tp_2.png";
import tp3 from "../assets/tp_3.png";
import tp4 from "../assets/tp_4.png";
import tp5 from "../assets/tp_5.jpg";
import tp6 from "../assets/tp_6.jpg";
import tp7 from "../assets/tp_7.png";
import tp8 from "../assets/tp_8.png";
import tp9 from "../assets/tp_9.png";
import tp10 from "../assets/tp_10.png";

import tp11 from "../assets/tp_11.png";
import tp12 from "../assets/tp_12.png";
import vs1 from "../assets/vs1.png";
import vs2 from "../assets/vs2.png";
import vs3 from "../assets/vs3.png";
import vs4 from "../assets/vs4.png";
import vs5 from "../assets/vs5.png";
import vs6 from "../assets/vs6.png";
import vs7 from "../assets/vs7.png";
import vs8 from "../assets/vs8.png";
import vs9 from "../assets/vs9.png";
import vs10 from "../assets/vs10.png";
import vs11 from "../assets/vs11.png";
import vs12 from "../assets/vs12.png";
import droughfm from "../assets/drought_formula.png";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import { connect } from "react-redux";
import { Document, Page } from "react-pdf";

import { locus, auth, project } from "../actions";

const PDF = process.env.REACT_APP_METHODOLOGY;

class Methodology extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}
  render() {
    const isDarkMode = localStorage.getItem("theme") == "dark";

    return (
      <>
        <Container
          fluid
          style={{ backgroundColor: "#18202F" }}
          className="sticky-top"
        >
          <Row>
            <br />
            <Breadcrumb>
              <Breadcrumb.Item>Methodology</Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <div className="met_parent">
          <Document file={PDF} options={{ workerSrc: "/pdf.worker.js" }}>
            {Array.from(new Array(39), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>

          {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item style={{ marginTop: "8%" }}>
                  <Nav.Link eventKey="first">Methodology</Nav.Link>

                  <Nav.Link eventKey="second">Assumptions</Nav.Link>
                  <Nav.Link eventKey="third">Variables and Losses</Nav.Link>
                  <Nav.Link eventKey="fourth">Risk Indices</Nav.Link>
                  <Nav.Link eventKey="fifth">Climate Terminology</Nav.Link>
                  <Nav.Link eventKey="sixth">Visualisation</Nav.Link>
                  <Nav.Link eventKey="seventh">FAQs</Nav.Link>
                  <Nav.Link eventKey="eigth">Summary</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <p
                    style={{
                      fontSize: "46px",
                      fontWeight: "bold",
                      color: "#18202F",
                      marginTop: "3.5%",
                      fontFamily: "montserrat",
                    }}
                  >
                    Methodology
                  </p>

                  <p style={{ fontSize: "21px", color: "#bdbdbd" }}>Summary</p>

                  <p style={{ fontSize: "21px", color: "#bdbdbd" }}>
                    Last edited: Nov 24 2021
                  </p>

                  <p className="met-para-1" style={{ fontSize: "18px" }}>
                    The BlueSwan Interface provides first-hand access to
                    projections of physical climate risks at the asset level. It
                    shows maps and graphs illustrating the projected changes in
                    climate conditions, resulting in impacts and damages on
                    selected sectors for several global warming levels, also how
                    they will play out over time according to various
                    policy-relevant emission scenarios by IPCC. All projections
                    are calculated assuming that socio-economic conditions
                    (population, land-use, management practices, etc.) will
                    remain constant as of 2020, therefore BlueSwan isolates the
                    sole effect of climate change on the indicators it provides
                    information on.{" "}
                  </p>

                  <p
                    className="met-para"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                    id="key"
                  >
                    Key Functionalities
                  </p>
                  <p className="met-para-2" style={{ fontSize: "16px" }}>
                    <ul class="a" style={{ fontSize: "18px" }}>
                      <li>
                        Projections of climate impacts at the asset level on
                        yearly scales:
                        <ul class="a_ol" style={{ fontSize: "18px" }}>
                          <li>
                            Including uncertainty ranges encompassing both the
                            global climate sensitivity to emissions and the
                            response of local impacts to global warming
                          </li>
                          <li>
                            Aggregation at the continental, national and
                            subnational levels using weighted averages by either
                            area, GDP, or population
                          </li>
                        </ul>
                      </li>
                      <li>
                        Time evolution of future impacts for policy-relevant
                        scenarios from Representative Concentration Pathways
                        (RCP)
                      </li>
                      <li>
                        Hazard and Country loss maps for different warming
                        levels containing information on the robustness of the
                        projections, based on the agreement between the various
                        climate and impact models used to derive them.
                      </li>
                      <li>
                        Climate and climate impact indicators covering several
                        biophysical sectors and economic damages from selected
                        extreme events
                      </li>
                      <li>
                        The possibility to download all displayed data, as well
                        as the data underlying them
                      </li>
                      <li>
                        The possibility to download a comprehensive ppt - report
                        covering all the assets, corresponding losses, graphs
                        with explanations
                      </li>
                    </ul>
                  </p>

                  <p
                    className="met-para"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    A Guidance Note for Users
                  </p>
                  <p className="met-para-3" style={{ fontSize: "18px" }}>
                    The Intensel Blueswan SaaS provides a comprehensive,
                    globally consistent dataset of physical risk projections for
                    different climate scenarios. These datasets are supplemented
                    by regional data to asset level granularity and financial
                    modeling to translate hazards into dollar Loss. However,
                    these losses and climate hazards are scenarios and
                    projection-based thus could not be compared with exact
                    events. The datasets from the platform can be used for
                    indicative risk and thus should be used in supplementing
                    national or regional risk assessments from governments or
                    consultants.
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div style={{ width: "90%" }}>
                    <br />
                    <br />
                    <p
                      style={{
                        marginTop: "2%",
                        color: "#18202F",
                        fontSize: "36px",
                        lineHeight: "1.2",
                        fontFamily: "montserrat",
                        fontWeight: "bold",
                      }}
                    >
                      Main Assumptions For Financial Loss Calculations
                    </p>
                    <br />
                    <br />
                    <p style={{ fontSize: "18px" }} className="met-para-4">
                      1. The Climate Value at Risk represents the Potential
                      YEARLY losses of the portfolio or asset in 2030-2050 or
                      2050-2070 <br />
                      <br />
                      2. The projected losses are the average of the maximum
                      extreme events under radiative forcing corresponding to
                      RCP <br />
                      8.5, that is business as usual with no transition policies
                      in place.
                      <br />
                      <br />
                      3. The projected losses are based on the damages to the
                      buildings, that is the estimated value of the property
                      using <br />
                      indicated area and the price per sqm. They can be
                      readjusted using either the construction value or the
                      replacement value. <br />
                      These data are internal data specific to the company and
                      assets under consideration.
                      <br />
                      <br />
                      4. It is the sum of physical damage + operation disruption
                      due to extreme events.
                      <br />
                      <br />
                      5. The projected/potential loss is different for Expected
                      loss. The Expected loss is therefore the probability
                      adjusted. <br />
                      Projected losses for the given carbon concentration
                      scenario (assume an RCPs 8.5 has a probability of 10%) and
                      the probability
                      <br />
                      that the extreme event is happening (ie 1:100 year return
                      period=1%). For example, the Expected loss of a
                      USD100million is <br />
                      = USD100million *10%*1%=USD100,000
                      <br />
                      <br />
                      6. The % losses are independent of the value of the
                      property. It is determined from the severity of climate
                      risk, <br />
                      geographical location, and the building characteristics
                      such as building material, height, terrain elevation, and
                      surrounding <br />
                      infrastructure. <br />
                      <br />
                      7. No time value is taking into consideration as the
                      discount factors is a subjective and company-specific
                      number.
                      <br />
                      <br />
                      8. When projecting losses related to climate risk, no
                      asset-specific resilience measure is taken into
                      consideration.
                      <br />
                      Nonetheless, we do use the best available satellite data
                      to check the surrounding infrastructure, ie roads, and
                      other <br />
                      water catchments to the degree that this information is
                      publicly available.
                      <br />
                      <br />
                      9. No insurance coverage is taken into account. That means
                      that part of the historical losses is covered from
                      insurance,
                      <br />
                      hence company reported losses are only a fraction of
                      realized losses.
                      <br />
                      <br />
                      10. Both the area and the price of the properties are kept
                      constant during the analysis, as the only variable here is
                      the <br />
                      climate change-related risk. Should one integrate the
                      impact of climate on GDP, valuation, and productivity,
                      <br />
                      the losses will most likely be much higher than the ones
                      projected in this analysis.
                      <br />
                    </p>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </Tab.Pane>

                <Tab.Pane eventKey="third">
                  <div style={{ marginTop: "3.5%", width: "90%" }}>
                    <Tabs
                      className="Extreme Heat"
                      defaultActiveKey="extremeheat"
                    >
                      <Tab title="Extreme Heat" eventKey="extremeheat">
                        <p
                          style={{
                            fontSize: "25px",
                            marginBottom: "1.5%",
                            color: "#18202F",
                            fontFamily: "montserrat",
                          }}
                        >
                          Electricity Loss Calculation
                        </p>
                        <div id="formula">
                          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                            Electricity Loss CalculationPredicted_Consumption =
                            Model(t,h,a)
                          </p>
                        </div>
                        <p
                          className="formula-para"
                          style={{ fontSize: "16px" }}
                        >
                          Predicted_Total_Cost =
                          (Predicted_Consumption)*Electricity_Cost(kwh) <br />
                          Here: <br />
                          <ul class="a">
                            <li> Model : Machine Learning Model.</li>
                            <li> t : Temperature in Degree Celsius </li>
                            <li> h : Humidity</li>
                            <li> a : Area of Building</li>
                          </ul>
                          Note : For Area a>(normal range) then our model
                          probably will not give optimal results, <br />
                          so we have used effective_electricity_cost(kwh) for
                          that building.
                        </p>

                        <div id="formula">
                          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                            Effective Electricy_Cost(kwh) =
                            ec-min(ec*k_factor,ec*area/100000)
                          </p>
                        </div>

                        <p style={{ fontSize: "16px" }}>
                          {" "}
                          Here: <br />
                          <ul class="a">
                            <li> ec : Electricity Cost (kwh)</li>
                          </ul>
                        </p>

                        <p
                          style={{
                            fontSize: "25px",
                            marginBottom: "1.5%",
                            color: "#18202F",
                          }}
                        >
                          Final methodology for electricity consumption pricing
                        </p>
                        <div id="formula">
                          <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                            Predicted_Consumption = Model(t,a,h) <br />{" "}
                            Predicted_Total_Cost =
                            (Predicted_Cosumption)*Effective_Electricity_Cost(kwh)
                          </p>
                        </div>

                        <p>
                          Note: Effective Electricity Cost can be used for all
                          areas because for small areas the <br /> modification
                          is negligible.
                        </p>

                        <p style={{ fontSize: "25px", color: "#18202F" }}>
                          Validation Metrics
                        </p>

                        <p
                          style={{
                            fontWeight: "bold",
                            marginBottom: "1.5%",
                            fontSize: "18px",
                          }}
                        >
                          Validation on UK Smart meter dataset
                          <br />
                          <img src={gp2} />
                        </p>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </Tab>

                      <Tab title="Rainfall Flood" eventKey="rainfall">
                        <p style={{ fontSize: "32px", color: "#18202F" }}>
                          Intensel's Flood Modelling{" "}
                        </p>
                        <div id="container">
                          <img src={rf1} />
                          <div id="child">
                            <h3>Achieving Higher Accuracy by</h3>
                            <p style={{ fontSize: "18px", marginTop: "5%" }}>
                              <ul class="a">
                                <li>
                                  {" "}
                                  DEM Preprocessing (Fill sink and
                                  Reconditioning)
                                </li>
                                <li>
                                  {" "}
                                  Using high resolution Rainfall data and
                                  considering <br />
                                  Land use Land cover parameter for assigning{" "}
                                  <br />
                                  roughness coefficient which controls flow
                                  velocity.
                                </li>
                                <li>
                                  {" "}
                                  Using Full momentum equation in HECRAS model &{" "}
                                  <br />
                                  Flo 2D model as all parameter like Gravity,
                                  Pressure, <br />
                                  Friction, Corollis effect, Acceleration and
                                  Turbulent <br />
                                  eddy viscosity is taken into consideration.
                                </li>
                                <li>
                                  {" "}
                                  Simulated results are validated using
                                  satellite images <br />
                                  of Synthetic Aperture Radar (SAR) and uses our{" "}
                                  <br />
                                  developed tool for estimating performance of
                                  flood <br />
                                  simulation models based on the counts of
                                  pixels <br />
                                  which are correctly predicted by the model,
                                  under <br />
                                  predicted by the model and over predicted by
                                  the model.
                                </li>
                              </ul>
                            </p>
                          </div>
                        </div>

                        <div className="rfdiv">
                          <p
                            style={{
                              fontSize: "32px",
                              color: "#18202F",
                              marginTop: "18%",
                            }}
                          >
                            Flood Validation with FEMA
                          </p>
                          <img
                            src={rf2}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />{" "}
                          <img
                            src={rf3}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />
                          <br />
                          <br />
                          <br />
                          <br />
                          <p style={{ fontSize: "32px", color: "#18202F" }}>
                            Validation Statistics
                          </p>
                          <div id="formula">
                            <p style={{ fontSize: "25px" }}>
                              With Respect to:- <br />
                              FEMACorrectly Predicted cells (A)- 277065O <br />
                              verpredicted cells – (B) - 178042 <br />
                              Under predicted cells – (C) - 84995 <br />
                              % increased in the flood area – 35% <br />
                              Measures of Fit <br />
                              F1 = A /(A+B+C) <br />
                              F1 = 0.51 <br />
                              Note – F1 ranges from 0 to 1 <br />
                              The value =1 is 100% accuracy
                            </p>
                          </div>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div>
                          <p style={{ fontSize: "32px", color: "#18202F" }}>
                            Flood Validation with FEMA (Depth)
                          </p>
                          <img
                            src={rf4}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />{" "}
                          <img
                            src={rf5}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />
                          <br />
                          <br />
                          <br />
                          <p style={{ fontSize: "32px", color: "#18202F" }}>
                            Vietnam Flood Modelling Validation
                          </p>
                          <img
                            src={rf6}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />{" "}
                          <img
                            src={rf7}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />
                          <br />
                          <br />
                          <br />
                          <p style={{ fontSize: "32px", color: "#18202F" }}>
                            Vietnam Flood Modelling Validation
                          </p>
                          <img
                            src={rf6}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />{" "}
                          <img
                            src={rf8}
                            style={{ marginLeft: "2%", width: "45%" }}
                          />
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </Tab>

                      <Tab title="Storm Surge" eventKey="stormsurge">
                        <Container>
                          <Row>
                            <Col lg={6} sm={4} xs={6}>
                              <p style={{ fontSize: "32px", color: "#18202F" }}>
                                Storm Surge Simulation
                              </p>
                              <p style={{ fontSize: "21px" }}>
                                The surge experienced for historic storms is
                                simulated using GeoClaw. <br />
                                <br />
                                It estimates surge using Riemann solvers are
                                used to solve 2d depth-averaged <br />
                                shallow water equations for flow over varying
                                topography. <br />
                                <br />
                                It takes storm properties from IBTrACS record.{" "}
                                <br />
                                <br />
                                The surge experienced is estimated from the
                                solution files generated. <br />
                                <br />
                                Bathymetric data at 500m resolution is used{" "}
                                <br />
                                for the simulation.
                              </p>
                            </Col>

                            <Col lg={6} sm={4} xs={6}>
                              <img src={ss1} />
                            </Col>
                          </Row>
                        </Container>

                        <Container fluid={true}>
                          <Row style={{ marginTop: "5%" }}>
                            <Col lg={6} sm={4} xs={6}>
                              <p style={{ fontSize: "32px", color: "#18202F" }}>
                                Sampling Surge Values
                              </p>
                              <p style={{ fontSize: "21px" }}>
                                The maximum surge experienced is sampled near{" "}
                                <br />
                                the coast as a 0 representative value. <br />
                                <br />
                                Surge values were estimated at grid size of 1km
                                x 1km. <br />
                                <br />
                                The simulation values were validated with
                                recorded <br />
                                maximum surge for the storm Manghut.
                              </p>
                              <Table id="t04">
                                <tr>
                                  <th>Station</th>
                                  <th>Observed</th>
                                  <th>Simulated</th>
                                  <th>Error</th>
                                </tr>
                                <tr>
                                  <td>Quarry Bay</td>
                                  <td>3.88</td>
                                  <td>3.10</td>
                                  <td>19.8%</td>
                                </tr>
                                <tr>
                                  <td>Shek Pik</td>
                                  <td>3.89</td>
                                  <td>3.28</td>
                                  <td>15.5%</td>
                                </tr>
                                <tr>
                                  <td>Tai Po Kau</td>
                                  <td>4.71</td>
                                  <td>2.95</td>
                                  <td>37.2%</td>
                                </tr>
                                <tr>
                                  <td>Tsim Bei Tsu</td>
                                  <td>4.18</td>
                                  <td>3.40</td>
                                  <td>18.5%</td>
                                </tr>
                              </Table>
                            </Col>

                            <Col lg={6} sm={4} xs={6}>
                              <img src={ss2} style={{ width: "1200px" }} />
                            </Col>
                          </Row>
                        </Container>

                        <Container fluid={true}>
                          <Row style={{ marginTop: "5%" }}>
                            <Col lg={6} sm={4} xs={6}>
                              <p style={{ fontSize: "32px", color: "#18202F" }}>
                                Estimating Inundation
                              </p>
                              <p style={{ fontSize: "21px" }}>
                                The inundation of surge into the land is
                                simulated <br />
                                using a high resolution DEM. <br />
                                <br />
                                A connected component based segmentation is used
                                to estimate <br />
                                the submerged regions. <br />
                                <br />
                                The domain is divided into numerous subdomains
                                for easy processing. <br />
                                <br />
                                The largest continuous zero value patch in DEM
                                is considered as ocean <br />
                                and is used as seed region. <br />
                                <br />
                                All submerged regions connected to the ocean
                                segment is evaluated <br />
                                and added to the result. <br />
                              </p>
                            </Col>

                            <Col lg={5} sm={6} xs={7}>
                              <img src={ss3} style={{ width: "1500px" }} />
                            </Col>
                          </Row>
                        </Container>

                        <div style={{ marginTop: "10%" }}>
                          <p
                            style={{
                              fontSize: "40px",
                              textAlign: "center",
                              color: "#18202F",
                              lineHeight: "1.2",
                            }}
                          >
                            Surge Inundation observed for a hypothetical 8m
                            Surge.
                          </p>
                          <img
                            src={ss4}
                            style={{ marginLeft: "13%", borderradius: "5%" }}
                          />
                        </div>

                        <div>
                          <p
                            style={{
                              fontSize: "40px",
                              textAlign: "left",
                              color: "#18202F",
                            }}
                          >
                            Geoclaw model.
                          </p>
                          <div id="formula">
                            <p style={{ fontSize: "25px" }}>
                              <p style={{ fontSize: "21px" }}>
                                {" "}
                                In one space dimension, the clawpack routine can
                                be used <br />
                                to solve a system of equations of the form κ(x)q
                                t + f (q) x = ψ(q, x, t), <br />
                                where q = q(x, t) lR m . <br />
                                The standard case of a homogeneous conservation
                                law has ∈κ ≡ 1 and ψ ≡ 0, <br />
                                q t + f (q) x = 0. <br />
                                The flux function f (q) can also depend
                                explicitly on x and t as well as on q. <br />
                                Hyperbolic systems that are not in conservation
                                form , <br />
                                q t + A(x, t)q x = 0, can also be solved. <br />{" "}
                              </p>
                            </p>
                          </div>
                          <br />
                          <p style={{ fontSize: "21px" }}>
                            For simulating the surge due to a typhoon, the
                            conservation laws are solved for <br />
                            a given bathymetry and wind forcing over the
                            surface. <br />
                            <br />
                            The temporal simulation gives the depth averaged
                            volume and velocity components <br />
                            of the disturbance. This is used to estimate the
                            surge due to disturbance. <br />
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                      </Tab>

                      <Tab title="Typhoon" eventKey="typhoon">
                        <div>
                          <p
                            style={{
                              fontSize: "32px",
                              textAlign: "left",
                              color: "#18202F",
                            }}
                          >
                            Cyclone Track Predictions
                          </p>
                          <p style={{ fontSize: "18px" }}>
                            {" "}
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              Objective{" "}
                            </span>
                            : Forecast the entire track of the cyclone based on
                            the single origin point and the time of forming.{" "}
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Input Features
                            </span>
                            : <br />
                            1)Latitude and Longitude of Origin.
                            <br />
                            2)Date and Time of forming the Origin.
                            <br />
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Additional Input Features
                            </span>
                            : (these will be searched on the fly by our model
                            itself) <br />
                            <br />
                            1)Temperatures (1 millibar to 1000 millibar)
                            <br />
                            2)UWind & VWind
                            <br />
                            3)SLI (Surface Lifted Index)
                            <br />
                            4)SLP (Sea level pressure)
                            <br />
                            5)Sea Temperatures
                            <br />
                            6)Omega
                            <br />
                            7)Geopotential Height
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                              Output Features
                            </span>
                            : The output will be a csv file containing
                            forecasted time, latitude <br />
                            and longitude of the eye of the cyclone at that
                            time.
                          </p>
                          <br />
                          <br />
                          <br />

                          <div>
                            <p
                              style={{
                                fontSize: "27px",
                                textAlign: "left",
                                color: "#18202F",
                              }}
                            >
                              Model Overview
                            </p>
                            <p style={{ fontSize: "18px" }}>
                              We have selected GRU+Attention Model for this
                              task, it is basically a deep neural network.{" "}
                              <br />
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                GRU Model
                              </span>
                              : <br />
                              GRU model basically has a temporal hidden memory
                              and it also has some selective gates <br />
                              for what to store and what not to store. This
                              model stores useful information of previous <br />
                              points of the same track in hidden memory much
                              like the human brain. <br />
                              <img src={tp1} style={{ marginLeft: "13%" }} />
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Attention Mechanism
                              </span>
                              : <br />
                              Attention is basically used to improve the
                              performance of GRU models because GRU <br />
                              vanishes some information if there is a very long
                              sequence or very long typhoon track, <br />
                              so attention can focus on any previous point.{" "}
                              <br />
                              <img src={tp2} style={{ marginLeft: "13%" }} />
                              <br />
                              <br />
                              <br />
                              <br />
                            </p>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "27px",
                                textAlign: "left",
                                color: "#18202F",
                              }}
                            >
                              Working of Model
                            </p>
                            <p style={{ fontSize: "18px" }}>
                              This is the Basic GRU Model Working with our
                              dataset
                              <img src={tp3} style={{ marginLeft: "13%" }} />
                            </p>
                          </div>
                          <br />
                          <br />
                          <br />
                          <div>
                            <p
                              style={{
                                fontSize: "27px",
                                textAlign: "left",
                                color: "#18202F",
                              }}
                            >
                              Validation Matrics
                            </p>
                            <p style={{ fontSize: "18px" }}>
                              Error Metric for Average Distance Error <br />
                              <br />
                              <br />
                              <br />
                              <br />
                              <img src={tp4} style={{ marginLeft: "13%" }} />
                            </p>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <div>
                            <p
                              style={{
                                fontSize: "27px",
                                textAlign: "left",
                                color: "#18202F",
                              }}
                            >
                              Insights from Model
                            </p>
                            <img src={tp5} style={{ marginLeft: "13%" }} />{" "}
                            <br />
                            <img src={tp6} style={{ marginLeft: "13%" }} />
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <p
                            style={{
                              fontSize: "32px",
                              textAlign: "left",
                              color: "#18202F",
                            }}
                          >
                            Calculation of Damage from Cyclone
                          </p>

                          <div>
                            <p style={{ fontSize: "18px" }}>
                              {" "}
                              <span style={{ fontWeight: "bold" }}>
                                {" "}
                                Objective{" "}
                              </span>
                              : Predict the damage based on any Cyclone, suppose
                              a cyclone brings x amount <br />
                              of rain with y wind speed and we have GDP Per
                              Capital of Landfall location then based on <br />
                              these we can Predict how much damage due to this
                              cyclone. <br />
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Input Features
                              </span>
                              : <br />
                              1)Rainfall <br />
                              2)Wind Speed (max) <br />
                              3)Population (200km) <br />
                              4)Total GDP (200km) <br />
                              5)GDP per capita <br />
                              Note : Rainfall Data can be collected using a
                              separate model, same with wind speed data can{" "}
                              <br />
                              be collected from a different deep neural network
                              model. <br />
                              <br />
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Output Features
                              </span>
                              : <br /> 1)Total damage due to cyclone <br />
                              <br />
                              <span style={{ fontWeight: "bold" }}>
                                Model Description
                              </span>
                              : <br /> We used random forest model totrain on
                              the historical dataset, <br />
                              inputs are the wind speed rainfall etc then the
                              model will predict total damage in US $ 2015,{" "}
                              <br />
                              due to those extreme conditions.
                              <br />
                              <br />
                              <br />
                              <img src={tp7} style={{ marginLeft: "13%" }} />
                              <br />
                            </p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div>
                              <p
                                style={{
                                  fontSize: "27px",
                                  textAlign: "left",
                                  color: "#18202F",
                                }}
                              >
                                Validation Metrics
                              </p>
                              <p style={{ fontSize: "18px" }}>
                                1)R2 Accuracy :
                              </p>
                              <img
                                src={tp8}
                                style={{ marginLeft: "13%", height: "400px" }}
                              />

                              <p style={{ fontSize: "18px" }}>
                                2)R2 Confidence intervals :
                              </p>
                              <img src={tp9} style={{ marginLeft: "13%" }} />

                              <p style={{ fontSize: "18px" }}>3)RMSE Error :</p>
                              <img src={tp10} style={{ marginLeft: "13%" }} />

                              <p style={{ fontSize: "18px" }}>
                                4)Feature Importance :
                              </p>
                              <img src={tp11} style={{ marginLeft: "7%" }} />

                              <p style={{ fontSize: "18px" }}>
                                5)Scatter Plot real vs Predicted :
                              </p>
                              <img src={tp12} style={{ marginLeft: "7%" }} />
                            </div>

                            <br />
                            <br />
                            <br />
                          </div>
                        </div>
                      </Tab>

                      <Tab title="Drought" eventKey="Drought">
                        <div>
                          <p
                            style={{
                              fontSize: "32px",
                              textAlign: "left",
                              color: "#18202F",
                            }}
                          >
                            Drought Methodology
                          </p>
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <ul class="a" style={{ fontSize: "18px" }}>
                            <li>
                              The drought methodology is based and derived from
                              RCM projections of <br /> “rainfall in the driest
                              quarter”
                            </li>
                            <br />
                            <li>
                              The formula used to calculate the drought index
                              which ranges between <br />0 to 100 is following
                            </li>
                            <br />
                            <br />
                            <br />
                            <br />
                            <img
                              src={droughfm}
                              style={{
                                marginLeft: "1%",
                                height: "55%",
                                width: "55%",
                                filter: "grayscale(100%)",
                              }}
                            />
                          </ul>

                          <p style={{ fontSize: "27px", textAlign: "left" }}>
                            Notes:
                          </p>
                          <ul class="a" style={{ fontSize: "18px" }}>
                            <li>
                              If drought risk is 100% that means the rainfall in
                              future projection would <br /> reduce equivalent
                              to “rainfall in driest quarter historical”
                            </li>
                            <br />
                            <li>
                              If drought is 0% means that the condition in the
                              driest quarter is either the same <br /> or
                              receiving more rainfall than “rainfall in driest
                              quarter historical”
                            </li>
                          </ul>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="fourth">
                  <Grid.Column
                    id="riskindices"
                    className="card"
                    style={{
                      height: "90%",
                      width: "89%%",
                      maxWidth: "100%",
                      overflowX: "auto",
                      overflowY: "hidden",
                      marginLeft: "90px",
                      marginTop: "20px",
                    }}
                  >
                    <h1>Extreme Heat</h1>
                    <table id="t01">
                      <tr>
                        <th>Temperature(Celsius)</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Every Degree Rise</th>
                      </tr>
                      <tr>
                        <td>0-27</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>27-32</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (5)</td>
                      </tr>
                      <tr>
                        <td>32-41</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (2.77)</td>
                      </tr>
                      <tr>
                        <td>41-54</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (1.92)</td>
                      </tr>
                      <tr>
                        <td>54-65</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (2.27)</td>
                      </tr>
                      <tr>
                        <td>>65</td>
                        <td style={{ color: "red", fontWeight: "100" }}>
                          Very High
                        </td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Rainfall Flood</h1>
                    <table id="t01">
                      <tr>
                        <th>Depth (m)</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in 0.1m Rise</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Less than 0.5</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (0.5)</td>
                      </tr>
                      <tr>
                        <td>0.5 - 1</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (0.5)</td>
                      </tr>
                      <tr>
                        <td>1 - 2</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (0.25)</td>
                      </tr>
                      <tr>
                        <td>2 - 5</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (0.083)</td>
                      </tr>
                      <tr>
                        <td>>5</td>
                        <td style={{ color: "red", fontWeight: "100" }}>
                          Very High
                        </td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>River Flood</h1>
                    <table id="t01">
                      <tr>
                        <th>Depth (m)</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in 0.1m Rise</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Less than 0.5</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (0.5)</td>
                      </tr>
                      <tr>
                        <td>0.5 - 1</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (0.5)</td>
                      </tr>
                      <tr>
                        <td>1 - 2</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (0.25)</td>
                      </tr>
                      <tr>
                        <td>2 - 5</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (0.083)</td>
                      </tr>
                      <tr>
                        <td>>5</td>
                        <td style={{ color: "red", fontWeight: "100" }}>
                          Very High
                        </td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Typhoon</h1>
                    <table id="t01">
                      <tr>
                        <th>Wind Speed (Km/h)</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in Wind Speed</th>
                      </tr>
                      <tr>
                        <td>Less Than 119</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>119 - 153</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (0.42)</td>
                      </tr>
                      <tr>
                        <td>178 - 208</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (0.83)</td>
                      </tr>
                      <tr>
                        <td>209 - 251</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (0.58)</td>
                      </tr>
                      <tr>
                        <td>251 - 350</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (0.25)</td>
                      </tr>
                      <tr>
                        <td> >350</td>
                        <td style={{ color: "red" }}>Very High</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Storm Surge</h1>
                    <table id="t01">
                      <tr>
                        <th>Depth (m)</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in 0.1m Rise</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Less Than 0.5</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + 0.5</td>
                      </tr>
                      <tr>
                        <td>0.5 - 1</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (0.5)</td>
                      </tr>
                      <tr>
                        <td>1 - 2</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (0.25)</td>
                      </tr>
                      <tr>
                        <td>2 - 5</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (0.083)</td>
                      </tr>
                      <tr>
                        <td>>5</td>
                        <td style={{ color: "red", fontWeight: "100" }}>
                          Very High
                        </td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Sea Level Rise</h1>
                    <table id="t01">
                      <tr>
                        <th>Level Rise(m)</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in m(s)</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td> >0</td>
                        <td style={{ color: "red" }}>Very High</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Drought</h1>
                    <table id="t01">
                      <tr>
                        <th>% Decrease in Driest Quarter</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th> % Decrese in Rainfall</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>0 - 25</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (1)</td>
                      </tr>
                      <tr>
                        <td>25 - 50</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (1)</td>
                      </tr>
                      <tr>
                        <td>50 - 75</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (1)</td>
                      </tr>
                      <tr>
                        <td>75 - 100</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (1)</td>
                      </tr>
                      <tr>
                        <td> More then 100 </td>
                        <td style={{ color: "red" }}>Very High</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Snow Fall</h1>
                    <table id="t01">
                      <tr>
                        <th>
                          Snow Fall Quantity 10<sup>-6</sup> (Kg-m)
                        </th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in Snow-Melt</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>6 - 7</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (2.5)</td>
                      </tr>
                      <tr>
                        <td>7 - 8</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (2.5)</td>
                      </tr>
                      <tr>
                        <td>8 - 8.5</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (5)</td>
                      </tr>
                      <tr>
                        <td>8.5 - 9</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (5)</td>
                      </tr>
                      <tr>
                        <td> >9</td>
                        <td style={{ color: "red" }}>Very High</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>

                    <h1>Landslide</h1>
                    <table id="t01" style={{ width: "80%" }}>
                      <tr>
                        <th>Score out of 100</th>
                        <th>Risk Severity</th>
                        <th>Risk Score</th>
                        <th>Increase in Score</th>
                      </tr>
                      <tr>
                        <td>0</td>
                        <td style={{ color: "green" }}>No Risk</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>0 - 25</td>
                        <td style={{ color: "#eddd00" }}>Low</td>
                        <td>25</td>
                        <td>0 + (1)</td>
                      </tr>
                      <tr>
                        <td>25 - 50</td>
                        <td style={{ color: "#fa9200" }}>Medium</td>
                        <td>50</td>
                        <td>25 + (1)</td>
                      </tr>
                      <tr>
                        <td>50- 75</td>
                        <td style={{ color: "#fa6000" }}>Medium High</td>
                        <td>75</td>
                        <td>50 + (1)</td>
                      </tr>
                      <tr>
                        <td>75 - 100</td>
                        <td style={{ color: "#fa0d00" }}>High</td>
                        <td>100</td>
                        <td>75 + (1)</td>
                      </tr>
                      <tr>
                        <td>100</td>
                        <td style={{ color: "red" }}>Very High</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                    </table>
                  </Grid.Column>
                </Tab.Pane>

                <Tab.Pane eventKey="fifth">
                  <Grid.Column
                    id="ct"
                    className="card"
                    style={{
                      height: "90%",
                      width: "99%",
                      maxWidth: "100%",
                      overflowX: "auto",
                      overflowY: "hidden",
                      marginLeft: "90px",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <p
                        style={{
                          fontSize: "40px",
                          color: isDarkMode ? "#4f7cac" : "#18202F",
                          fontFamily: "montserrat",
                          fontWeight: "bold",
                        }}
                      >
                        What are Climate Scenarios?
                      </p>
                      <p className="met-para-5" style={{ fontSize: "18px" }}>
                        RCPs usually refer to the portion of the concentration
                        pathway extending up to 2100, for which Integrated
                        Assessment Models produced corresponding emission
                        scenarios: <br />
                        <br />
                        <span
                          style={{
                            color: isDarkMode ? "#4f7cac" : "#18202F",
                            fontWeight: "bold",
                          }}
                        >
                          RCP2.6
                        </span>{" "}
                        One pathway where radiative forcing peaks at
                        approximately 3 W m-2 before 2100 and then declines (the
                        corresponding ECP assuming constant emissions after
                        2100). <br />
                        <br />
                        <span
                          style={{
                            color: isDarkMode ? "#4f7cac" : "#18202F",
                            fontWeight: "bold",
                          }}
                        >
                          RCP4.5
                        </span>{" "}
                        is an intermediate stabilization pathways in which
                        radiative forcing is stabilized at approximately 4.5 W
                        m-2 and 6.0 W m-2 after 2100. <br />
                        <br />
                        <span
                          style={{
                            color: isDarkMode ? "#4f7cac" : "#18202F",
                            fontWeight: "bold",
                          }}
                        >
                          RCP8.5
                        </span>{" "}
                        RCP 8.5 refers to the concentration of carbon that
                        delivers global warming at an average of 8.5 watts per
                        square meter across the planet. The RCP 8.5 pathway
                        delivers a temperature increase of about 4.3˚C by 2100.
                      </p>
                      <br />
                      <div
                        style={{
                          padding: "2%",
                          backgroundColor: "#f2f2f2",
                          borderRadius: "15px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontFamily: "montserrat",
                            lineHeight: "28px",
                          }}
                        >
                          The goal of working with scenarios is not to predict
                          the future, but to better understand the uncertainties
                          and potential alternative futures. This allows us to
                          consider how robust different decisions or options may
                          be under a wide range of possible scenarios..
                        </p>
                        <img src={ct1} />
                      </div>
                      <br />
                      <br />
                      <br />

                      <p
                        style={{
                          fontSize: "40px",
                          color: isDarkMode ? "#4f7cac" : "#18202F",
                          fontFamily: "montserrat",
                          fontWeight: "bold",
                        }}
                      >
                        What is the Return Period?
                      </p>
                      <div
                        style={{
                          padding: "2%",
                          backgroundColor: "#f2f2f2",
                          borderRadius: "15px",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "18px",
                            fontFamily: "montserrat",
                            lineHeight: "28px",
                          }}
                        >
                          The value of 100 years does not mean that the
                          structure will be at risk exactly every 100 yrs.
                          Rather, it means that the structure will be at risk,
                          say 10 times every 1000 years.
                          <br />
                          The 100-yr flood peak applies for flood plain
                          development, medium-sized flood protection works, and
                          regional urban drainage facilities.
                        </p>
                        <p style={{ fontSize: "14px" }}>
                          <img style={{ align: "center" }} src={ct2} /> <br />
                          <br />
                          Figure: Design of Levee based on 250 year return
                          period of river at Tecate Creek, Baja California,
                          Mexico
                        </p>
                      </div>
                      <br />
                      <br />
                      <br />

                      <p
                        style={{
                          fontSize: "40px",
                          color: isDarkMode ? "#4f7cac" : "#18202F",
                          fontFamily: "montserrat",
                          fontWeight: "bold",
                        }}
                      >
                        Guidelines for selection of Return Periods.
                      </p>
                      <br />
                      <div style={{ width: "110%" }}>
                        <table id="t03">
                          <tr>
                            <th>No.</th>
                            <th>Type of project or feature</th>
                            <th>Return Period(yr)</th>
                          </tr>
                          <tr>
                            <td>1</td>
                            <td>Urban drainage [low risk] (up to 100 ha)</td>
                            <td>5 to 10</td>
                          </tr>
                          <tr>
                            <td> 2</td>
                            <td>
                              Urban drainage [medium risk] (more than 100 ha)
                            </td>
                            <td>25 to 250</td>
                          </tr>
                          <tr>
                            <td> 3</td>
                            <td>Road drainage</td>
                            <td>25 to 100</td>
                          </tr>
                          <tr>
                            <td> 4</td>
                            <td>Principal spillways (dams)</td>
                            <td>25 to 100</td>
                          </tr>
                          <tr>
                            <td> 5</td>
                            <td>Highway drainage</td>
                            <td>50 to 250</td>
                          </tr>
                          <tr>
                            <td> 6</td>
                            <td>Levees [medium risk]</td>
                            <td>50 to 250</td>
                          </tr>
                          <tr>
                            <td> 7</td>
                            <td>
                              Urban drainage [high risk] (more than 1,000 ha)
                            </td>
                            <td>50 to 250</td>
                          </tr>
                          <tr>
                            <td> 8</td>
                            <td>Flood plain development</td>
                            <td>100</td>
                          </tr>
                          <tr>
                            <td> >9</td>
                            <td>Insurance premium calculations</td>
                            <td>250</td>
                          </tr>
                          <tr>
                            <td> 10</td>
                            <td>Bridge design (piers)</td>
                            <td>100 to 500</td>
                          </tr>
                          <tr>
                            <td> 11</td>
                            <td>Levees [high risk]</td>
                            <td>200 to 1000</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </Grid.Column>
                </Tab.Pane>

                <Tab.Pane eventKey="sixth">
                  <div style={{ width: "90%" }}>
                    <p
                      style={{
                        fontSize: "40px",
                        color: "#18202F",
                        fontWeight: "bold",
                        marginTop: "3.5%",
                        marginBottom: "3.5%",
                        fontFamily: "montserrat",
                      }}
                    >
                      {" "}
                      Bar Graphs{" "}
                    </p>
                    <div
                      style={{
                        padding: "2%",
                        backgroundColor: "#f2f2f2",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "montserrat",
                          lineHeight: "28px",
                        }}
                      >
                        The bar graph plots show how projected impacts of
                        climate related losses unfold with time and according to
                        the selected scenarios The Graphs gives the insights on
                        how climate related losses on the selected losses would
                        increase with IPCC scenarios.{" "}
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "montserrat",
                          lineHeight: "28px",
                        }}
                      >
                        The time range is 2020, 2030 (year 2030 to 2050 time
                        period) and 2050 (year 2050 to 2070 time period). The
                        graphs give clear indications on how the losses would
                        increase from current base period (i.e., 2020) as well
                        as how the losses with acerbate from a very string
                        scenario (RCP 2.6) to business as usual scenario (RCP
                        8.5)
                      </p>
                      <img
                        src={vs1}
                        style={{
                          width: "80%",
                          marginTop: "30px",
                          marginBottom: "30px",
                        }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        <span style={{ fontWeight: "bold" }}>Fig. 1 </span>{" "}
                        Figure shows change in Dollar losses and with respect to
                        years and Representative concentration pathways
                      </p>
                    </div>

                    <p
                      style={{
                        fontSize: "40px",
                        color: "#18202F",
                        fontWeight: "bold",
                        marginTop: "80px",
                        fontFamily: "montserrat",
                        lineHeight: "28px",
                      }}
                    >
                      Climate Risk Progress Bars{" "}
                    </p>
                    <div
                      style={{
                        padding: "2%",
                        backgroundColor: "#f2f2f2",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "montserrat",
                          lineHeight: "28px",
                        }}
                      >
                        The Risk severity charts shows the severity climate
                        hazard scores. Here low scores means that assets in the
                        portfolio have low exposure to the hazard selected while
                        high score means that assets are located on high risky
                        zone. The lowest score is zero for progress bar and
                        highest is 100.
                        <br />
                        Here climate value at risk is the expected maximum
                        losses from climate hazard per year while maximum
                        exposure is the sum of highest loss the assets in the
                        portfolio from a particular climate hazard.
                      </p>
                      <img
                        src={vs2}
                        style={{
                          width: "45%",
                          marginTop: "30px",
                          marginBottom: "30px",
                        }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        <span style={{ fontWeight: "bold" }}>Fig. 2 </span>{" "}
                        Figure shows severity of climate hazard along with max
                        probable yearly loss and max expected exposure from any
                        hazard posing highest loss on the portfolio
                      </p>
                    </div>

                    <p
                      style={{
                        fontSize: "40px",
                        color: "#18202F",
                        fontWeight: "bold",
                        marginTop: "80px",
                        fontFamily: "montserrat",
                        lineHeight: "28px",
                      }}
                    >
                      Progress Bars{" "}
                    </p>
                    <div
                      style={{
                        padding: "2%",
                        backgroundColor: "#f2f2f2",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "montserrat",
                          lineHeight: "28px",
                        }}
                      >
                        There are two progress bars that represent the severity
                        of hazard in low low, medium and high as well as
                        potential losses. These provide breakdown for the losses
                        and severity for each climate variables. They also
                        provide info on direct damages losses and operational
                        losses, giving the transparency on the losses in the
                        protfolio.{" "}
                      </p>
                      <img
                        src={vs10}
                        style={{ width: "35%", marginTop: "30px" }}
                      />
                      <img
                        src={vs11}
                        style={{ width: "35%", marginTop: "px" }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        <span style={{ fontWeight: "bold" }}>Fig. 3 </span>{" "}
                        Figure shows the breakdown of climate hazard severity
                        and a loss breakdown corresponding to different
                        variables using progress charts
                      </p>{" "}
                    </div>

                    <p
                      style={{
                        fontSize: "40px",
                        color: "#18202F",
                        fontWeight: "bold",
                        marginTop: "80px",
                        fontFamily: "montserrat",
                        lineHeight: "28px",
                      }}
                    >
                      Asset Heat-Maps
                    </p>
                    <div
                      style={{
                        padding: "2%",
                        backgroundColor: "#f2f2f2",
                        borderRadius: "15px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontFamily: "montserrat",
                          lineHeight: "28px",
                        }}
                      >
                        The asset-variable heat maps enable users to access the
                        severity of all the variables on each asset in a single
                        cursory view. The size of the bubble represents higher
                        intensity of hazard while smaller bubble represents
                        lower severity. The max value of hazard for this map is
                        10 and lowest is 0. The severity of the hazards is also
                        represented with colour coding I.e., yellow (low risk)
                        to red (high risk){" "}
                      </p>
                      <img
                        src={vs8}
                        style={{ width: "57%", marginTop: "30px" }}
                      />
                      <p style={{ fontSize: "14px" }}>
                        <span style={{ fontWeight: "bold" }}>Fig. 4 </span>{" "}
                        Figure shows heat scores of all the selected climate
                        variables. The minimum score is 0 (no risk) and max
                        score is 10 (highest risk). The size of the bubbles
                        represents intensity of hazard
                      </p>
                    </div>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="seventh">
                  <div>
                    <Grid.Column
                      id="riskindices"
                      className="card"
                      style={{
                        height: "90%",
                        width: "95%%",
                        maxWidth: "100%",
                        overflowX: "auto",
                        overflowY: "hidden",
                        marginLeft: "90px",
                        marginTop: "20px",
                      }}
                    >
                      <nav id="topbar-wrap">
                        <ul className="topbar">
                          <li>
                            <a class="active" href="#riskindices">
                              General Queries
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div style={{ width: "90%" }}>
                        <Accordion>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                1. How is the Climate Value at Risk %
                                Calculated?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                Individual Climate Value at Risk % is calculated
                                by Taking the Individual Asset Loss and Dividing
                                this number by the asset valuation. Total
                                Climate value at risk is calculated by taking
                                the Total combined losses and dividing by the
                                total Maximum Exposure.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                2. Why do assets in the same geographical region
                                not share sea level rise risk statistics?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                Intensel uses HK Observatory district data set
                                with granularity at 30 meters. We use SRTM
                                (Shuttle Radar Topography Mission) as DEM
                                (Digital elevation Model) data as these are more
                                accurate than Coastal Dem (used by Climate
                                Central) data especially for small islands such
                                as HK Island.
                                <br />
                                Intensel's results do not reflect the access to
                                the assets due to the inundation of road network
                                and surrounding area.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                3. From climate risk assessment perspective,
                                should we focus on the assets which are the most
                                exposed to climate hazards or which have the
                                highest financial loss?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                The focus should be on the asset with higher
                                potential loss and higher exposure to climate
                                hazards (exposure=probability 1% *severity of
                                climate). The probability/likelihood is the 1%
                                across all hazards, but the severity of climate
                                hazards is not the same on various assets.
                                Nonetheless, to be able to use the dollar loss
                                Intensel would recommend doing so only when each
                                asset has All the asset relevant data, ie
                                revenues generated in that location, area, cost,
                                valuation, ownership etc. At least need to be
                                more accurate on how much of that loss you own
                                (ownership). If you do not have that
                                information, the financial dollar loss number is
                                potentially overstated.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                4. How is the Maximum exposure calculated?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                Maximum Exposure is calculated as the valuation
                                of all assets that receive climate risk loss,
                                those that do not receive damage will not be
                                included in the Maximum exposure.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                5. How are the climate risk variables estimated
                                – is it based on HK experience?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                There are over nine climate risk variables on
                                the Intensel blue-swan platform, estimations of
                                most variables vary from one to another. We try
                                to model these variables according to the best
                                standards and peer-reviewed models. Further, as
                                the platform provides global analysis, we’re
                                developing a uniformed modelling approach
                                globally. However, several inputs are
                                region-specific, for example, DEMs, bathymetry,
                                footprints, historical data; thus, model output
                                is both scalable and includes region specific
                                information, Pluvial/fluvial Flood Modelling:
                                In-house models using HEC (Hydrologic
                                Engineering Centre) and Flow-2d. Storm Surge:
                                HEC and Geo hydrodynamic models.’ Extreme heat:
                                outputs from integrated GCM-RCM models. These
                                are in-house models with validation available
                                upon request.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                6. The attached xls contains different levels of
                                climate risk variables for different years. Is
                                the number an average value under different
                                scenario-year? Would the entire probability
                                distribution across years be available upon
                                request?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                The outputs are based on recommendations by
                                TCFD, where the recommendations are to provide
                                physical climate risk for two time periods,
                                2030-2050 and 2050 -2070 only. Though we can
                                generate these events yearly, we neither
                                recommend nor prefer this for two reasons: 1. As
                                we provide asset-level granularity, the size of
                                the datasets increases exponentially and can go
                                beyond 100 terabytes as 9 variables, global
                                yearly for 70+ years. This much data is
                                challenging to handle as cloud infrastructure
                                handling becomes arduous. 2. Climate data does
                                not make sense yearly, unlike the weather.
                                Climate data’s mean over the time variables
                                makes more sense as the uncertainty is reduced
                                when averaged over the time period. It gives a
                                much better estimation of extremes and trends.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                7. How does the climate risk variable translate
                                to Asset Damage and Operational Loss – is this
                                based on HK experiences?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                The losses are based on the insurance approach
                                wherein Mean Damage Ratio (MDR) is modelled for
                                different levels of floods and surges for types,
                                floors, basements of assets. The MDR approach is
                                documented in various journals. Temperature loss
                                models are trained on historical losses because
                                of the increase/decrease in energy consumption
                                and change in temperature and humidity; both are
                                modelled from outputs of integrated GCM-RCMs.
                                (Ref: 10.1007/s11069-013-1017-z
                                https://doi.org/10.1007/s11069-013-1017-z ,
                                http://hdl.handle.net/2117/22402 etc.) The
                                typhoon loss is based on calculations of
                                coefficients for factors that lead to typhoon
                                losses and trained with historical data. This
                                was developed at Intensel with our PhD who also
                                published a paper on this loss calculation
                                approach (Ref:
                                https://doi.org/10.3389/fbuil.2020.575534)
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                8. Does the asset damage translate to property
                                valuation decrease, and will operational loss
                                translate to a reduction in property income? How
                                should we interpret the loss number in the xls?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                We provide the ability to adjust property
                                valuation according to the client both using
                                APIs and UI (User Interface). A client who has
                                already calculated an increase/decrease in
                                valuation can provide these inputs on their end.
                                This is done as an increase and decrease in
                                property valuation depends on several variables
                                and varies from region to region; modelling this
                                is not our expertise. The operations loss
                                considers the increase/decrease in economic
                                activity in a year; however, this is done with
                                the assumption that increase/decrease in all the
                                regions is directly proportional to the
                                increase/decrease in GDP of a country in the
                                coming 60-year period.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                9. Were assumptions made when aggregating the
                                damage/loss across perils? Is consideration in
                                the correlation between different climate risk
                                variables?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                While aggregating the losses, we made sure that
                                these individual variable-losses were mutually
                                exhaustive and did not overlap. As all the
                                outputs are probable max loss, what we give
                                clients is a total and break down individual
                                losses to better understand the risk. Though the
                                losses are mutually exhaustive, two events might
                                occur together, like extreme temperature and
                                storm surge. The co-occurrence is very difficult
                                to model accurately. Hence the client can use
                                the individual losses and can create his own set
                                of co-occurring events.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>

                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                10. Is there an estimation of typhoon risk?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ fontSize: "16px" }}>
                                Intensel uses multiple in-house models for
                                typhoon risk (mainly rain and wind from
                                typhoons). These models take into consideration
                                some of the essential factors for modelling
                                typhoon risk, such as changes in wind intensity
                                over time, change in 5 days cumulative rainfall
                                from the typhoon, deviation in typhoon tracks
                                and landfalls (this has a significant impact on
                                exposure) and lastly the prediction of
                                month/year and location of origin of typhoons.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </Grid.Column>
                  </div>
                </Tab.Pane>

                <Tab.Pane eventKey="eigth">
                  <h1 id="h2sum">
                    Summary SaaS model worksheet for BD Team (Updated on 1 JUN
                    2022)
                  </h1>
                  <Row>
                    <Col lg={12} style={{ overflowX: "auto" }}>
                      <table class="tablesum">
                        <thead>
                          <tr>
                            <th scope="col" colspan="1">
                              Hazard
                            </th>
                            <th scope="col" colspan="4">
                              SAAS
                            </th>
                            <th scope="col" colspan="2">
                              Deep Analysis
                            </th>
                            <th scope="col" colspan="2">
                              Risk Index
                            </th>
                            <th scope="col" colspan="1">
                              Notes
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr id="row2">
                            <td scope="row"></td>
                            <td colspan="2">Modelled</td>
                            <td colspan="2">Not Modelled</td>
                            <td colspan="2">Modelled</td>
                            <td colspan="2">Modelled</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td scope="row"></td>
                            <td>Name (source)</td>
                            <td>Validation/Accuracy</td>
                            <td>Model not available</td>
                            <td>Data source</td>
                            <td>Name (source)</td>
                            <td>Validation/Accuracy</td>
                            <td>Name if YES</td>
                            <td>Validation/Accuracy</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td scope="row">Rainfall Flood</td>
                            <td>
                              [Widely Adapted Model] HEC-RAS (Rain on grid),
                              Flo-2d (urban)
                            </td>
                            <td>80%</td>
                            <td></td>
                            <td></td>
                            <td>HEC-RAS (result with Time Step) </td>
                            <td>80%</td>
                            <td>Analytical Hierarchy Process</td>
                            <td>NA (As Judgemental) </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td scope="row">River flood</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td></td>
                            <td>GLOBAL RASTER DATASET</td>
                            <td>HECRAS (Discharge input to river cells)</td>
                            <td>80%</td>
                            <td>Analytical Hierarchy Process</td>
                            <td>NA (As Judgemental) </td>
                            <td></td>
                          </tr>

                          <tr>
                            <td scope="row">Snow Fall</td>
                            <td>
                              No Model/Downscaled for Improve the resolution
                              using (SGAN Model)
                            </td>
                            <td></td>
                            <td></td>
                            <td>Chelsa/IPCC-CMIP5-GCM's</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <td scope="row">Temperature </td>
                            <td>
                              No Model/Downscaled for Improve the resolution
                              using (SGAN Model)
                            </td>
                            <td></td>
                            <td></td>
                            <td>Chelsa/IPCC-CMIP5-GCM's</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <td scope="row">Sea Level Rise </td>
                            <td>
                              [Intensel Model] Global Coastal areas Inundation
                              based on Mean sea level rise projections using
                              Region growing Model
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <td scope="row">Storm Surge </td>
                            <td>
                              [Intensel Model] Based on peer reviwed article and
                              inhouse developed technique for shore connected
                              region segmentation{" "}
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              GESLA‑2: Global extreme sea level analysis version
                              2 (tide gauge data).
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              Normalised maximum surge depth over 9x9 nearest
                              neighbour
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              Refe article: Kirezci, E., Young, I.R.,
                              Ranasinghe, R. et al. Projections of global-scale
                              extreme sea levels and resulting episodic coastal
                              flooding over the 21st Century. Sci Rep 10, 11629
                              (2020). https://doi.org/10.1038/s41598-020-67736-6
                            </td>
                          </tr>

                          <tr>
                            <td scope="row">Extreme Heat </td>
                            <td>
                              [Widely Adapted Model] Window based max over
                              5years from GCM predictions
                            </td>
                            <td></td>
                            <td></td>
                            <td>IPCC-CMIP5 GCMs</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <td scope="row">Landslide </td>
                            <td>
                              No Model/Downscaled for Improve the resolution
                              using (SGAN Model)
                            </td>
                            <td></td>
                            <td></td>
                            <td>NASA</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Historical land silde data </td>
                          </tr>

                          <tr>
                            <td scope="row">Typhoon </td>
                            <td>
                              [Intensel RCP Model but Lost the methodology]. We
                              are trying to build a model.{" "}
                            </td>
                            <td></td>
                            <td></td>
                            <td>NASA</td>
                            <td></td>
                            <td></td>
                            <td>Analytical Hierarchy Process</td>
                            <td>NA (As Judgemental) </td>
                          </tr>

                          <tr>
                            <td scope="row">Drought </td>
                            <td>
                              No Model/Downscaled for Improve the resolution
                              using (SGAN Model){" "}
                            </td>
                            <td></td>
                            <td></td>
                            <td>SPI - IPCC -CMIP6 - GCM </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  {/* <h1 id="h2sum2">Summary SaaS model worksheet for BD Team (Updated on 1 JUN 2022)</h1>

              <table class="tablesum">
                <thead>
                  <tr>
                    <th scope="col">Hazard</th>
                    <th scope="col" colspan="4">SAAS</th>
                    <th scope="col" colspan="2">Deep Analysis</th>
                    <th scope="col" colspan="2">Risk Index</th>
                    <th scope="col">Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row"></td>
                    <td colspan="2">Modelled</td>
                    <td colspan="2">Not Modelled</td>
                    <td colspan="2">Modelled</td>
                    <td colspan="2">Modelled</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td scope="row"></td>
                    <td>Name (source)</td>
                    <td>Validation/Accuracy</td>
                    <td>Model not available</td>
                    <td>Data source</td>
                    <td>Name (source)</td>
                    <td>Validation/Accuracy</td>
                    <td>Name if YES</td>
                    <td>Validation/Accuracy</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td scope="row">Rainfall Flood</td>
                    <td>[Widely Adapted Model] HEC-RAS (Rain on grid), Flo-2d (urban)</td>
                    <td>80%</td>
                    <td></td>
                    <td></td>
                    <td>HEC-RAS (result with Time Step)    </td>
                    <td>80%</td>
                    <td>Analytical Hierarchy Process</td>
                    <td>NA (As Judgemental) </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td scope="row">River flood</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td></td>
                    <td>GLOBAL RASTER DATASET</td>
                    <td>HECRAS (Discharge input to river cells)</td>
                    <td>80%</td>
                    <td>Analytical Hierarchy Process</td>
                    <td>NA (As Judgemental) </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td scope="row">Snow Fall</td>
                    <td>No Model</td>
                    <td></td>
                    <td></td>
                    <td>IPCC-CMIP5-GCM's</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td scope="row">Temperature </td>
                    <td>No Model/Downscaled for Improve the resolution using (SGAN Model)</td>
                    <td></td>
                    <td></td>
                    <td>IPCC-CMIP5-GCM's</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td scope="row">Sea Level Rise  </td>
                    <td>[Intensel Model] Global Coastal areas Inundation based on Mean sea level rise projections using Region growing Model</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td scope="row">Storm Surge </td>
                    <td>[Intensel Model] Based on peer reviwed article and inhouse developed technique for shore connected region segmentation </td>
                    <td></td>
                    <td></td>
                    <td>GESLA‑2: Global extreme sea level analysis version 2 (tide gauge data).</td>
                    <td></td>
                    <td></td>
                    <td>Normalised maximum surge depth over 9x9 nearest neighbour</td>
                    <td></td>
                    <td> Refe article: Kirezci, E., Young, I.R., Ranasinghe, R. et al. Projections of global-scale extreme sea levels and resulting episodic coastal flooding over the 21st Century. Sci Rep 10, 11629 (2020). https://doi.org/10.1038/s41598-020-67736-6</td>
                  </tr>  

                  <tr>
                    <td scope="row">Extreme Heat </td>
                    <td>[Widely Adapted Model] Window based max over 5years from GCM predictions</td>
                    <td></td>
                    <td></td>
                    <td>IPCC-CMIP5 GCMs</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr> 

                  <tr>
                    <td scope="row">Landslide </td>
                    <td>No Model</td>
                    <td></td>
                    <td></td>
                    <td>NASA</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Historical land silde data </td>
                  </tr>

                   <tr>
                    <td scope="row">Typhoon </td>
                    <td>[Intensel RCP Model but Lost the methodology]. We are trying to build a model. </td>
                    <td></td>
                    <td></td>
                    <td>NASA</td>
                    <td></td>
                    <td></td>
                    <td>Analytical Hierarchy Process</td>
                    <td>NA (As Judgemental) </td>
                  </tr>

                  <tr>
                    <td scope="row">Drought </td>
                    <td>No Model/Downscaled for Improve the resolution using (SGAN Model) </td>
                    <td></td>
                    <td></td>
                    <td>SPI - IPCC -CMIP6 - GCM </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                </tbody>
              </table>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>*/}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    insurance: state.project.insurance,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getInsurance: (formdata) => {
      dispatch(project.getInsurance(formdata));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Methodology);
