import React from "react";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import { Row, Col } from "react-bootstrap";
const CompanyHeatmap = (props) => {
  let heatmapdata = [];
  let commonProps = {};

  console.log("heatmap_classic", props.data.values[0]["River Flood"]);

  for (let i = 0; i < 2; i++) {
    heatmapdata.push({
      id: props.data.names[i],

      data: [
        {
          x: "River Flood",
          y: props.data.values[i]["River Flood"],
        },

        { x: "Drought", y: props.data.values[i]["Drought"] },
        { x: "Storm Surge", y: props.data.values[i]["Storm Surge"] },
        { x: "Extreme heat", y: props.data.values[i]["Extreme Heat"] },
        { x: "Snow Fall", y: props.data.values[i]["Snow Fall"] },
        { x: "Rainfall Flood", y: props.data.values[i]["Rainfall Flood"] },
        { x: "Typhoon", y: props.data.values[i]["Typhoon"] },
        { x: "Sea Level Rise", y: props.data.values[i]["Sea Level Rise"] },
        { x: "Landslide", y: props.data.values[i]["Landslide"] },
        { x: "Wild Fire", y: props.data.values[i]["Wild Fire"] },
      ],
    });
  }
  let n = props.data.names.length;

  if (n == 1) {
    commonProps = {
      width: 1150,
      height: 200,
    };
  } else {
    commonProps = {
      width: 1150,
      height: n * 120,
    };
  }
  console.log("data_heatmap", heatmapdata, props.variable);

  return (
    <ResponsiveHeatMap
      {...commonProps}
      data={heatmapdata}
      keys={props.variable}
      indexBy="id"
      hoverTarget="cell"
      margin={{ top: 90, right: 0, bottom: 80, left: 220 }}
      valueFormat=">-.2s"
      isInteractive={false}
      axisTop={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -54,
        legend: "",
        legendOffset: 46,
      }}
      colors={{
        type: "diverging",
        scheme: "yellow_orange_red",
        divergeAt: 0.5,
        minValue: 0,
        maxValue: 100,
      }}
      emptyColor="#555555"
      /*legends={[
        {
          anchor: "bottom",
          translateX: 0,
          translateY: 30,
          length: 400,
          thickness: 8,
          direction: "row",
          tickPosition: "after",
          tickSize: 3,
          tickSpacing: 4,
          tickOverlap: false,
          tickFormat: ">-.2s",
          title: "Value →",
          titleAlign: "start",
          titleOffset: 4,
        },
      ]}*/
    />
  );
};

export default CompanyHeatmap;
